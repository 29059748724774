import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import AreaAddContainer from "../view/AreaAddContainer";
import AreaListContainer from "../view/AreaListContainer";
import RegionAddContainer from "../view/Geo/Region/RegionAddContainer";
import RegionListContainer from "../view/Geo/Region/RegionListContainer";
import TerritoryAddContainer from "../view/Geo/Territory/TerritoryAddContainer";
import TerritoryListContainer from "../view/Geo/Territory/TerritoryListContainer";
import ZoneAddContainer from "../view/Geo/Zone/ZoneAddContainer";
import ZoneListContainer from "../view/Geo/Zone/ZoneListContainer";
import RemoteSalesListContainer from "../view/RemoteSales/RemoteSales/RemoteSalesListContainer";
import RemoteSalesEditContainer from "../view/RemoteSales/RemoteSales/RemoteSalesEditContainer";
import DistributorReportsContainer from "../view/RemoteSales/DistributorReports/DistributorReportsContainer";
import DistributorReportsDrillContainer from "../view/RemoteSales/DistributorReports/DistributorReportsDrillContainer";
import SalesReportsListContainer from "../view/RemoteSales/DistributorReports/SalesReportsListContainer";
import DistributorReportsBrContainer from "../view/RemoteSales/DistributorReports/DistributorReportsBrContainer";
import CustomerDeliveryReportsContainer from "../view/RemoteSales/DistributorReports/CustomerDeliveryReportsContainer";
import DistributorProductDeliverListContainer from "../view/RemoteSales/RemoteSales/DistributorProductDeliverListContainer";
import SalesApprovedReportListContainer from "../view/RemoteSales/RemoteSales/SalesApprovedReport/SalesApprovedReportListContainer";
import SecondarySalesListContainer from "../view/RemoteSales/RemoteSales/SecondarySalesList/SecondarySalesListContainer";
import SecondarySalesPivotContainer from "../view/RemoteSales/RemoteSales/SecondarySalesPivot/SecondarySalesPivotContainer";

import ProfileScreen from "../view/RemoteSales/profileupdate/ProfileScreen";
import SecondarySalesPivotPage from "../information/components/RemoteSales/secondarySalesList/SecondarySalesPivotPage";
import SecondarySalesAddContainer from "../view/RemoteSales/SecondarySales/SecondarySalesAddContainer";
import SecondaryListContainer from "../view/RemoteSales/SecondarySales/SecondaryListContainer";
import SecondaryCumalativeContainer from "../view/RemoteSales/SecondarySales/SecondaryCumalativeContainer";
import SecondarySalesEdit from "../information/components/SecodarySales/SecondarySalesEdit";
import SecondarySalesPivotFilter from "../information/components/RemoteSales/secondarySalesPivot/SecondarySalesPivotFilter";
import SecondaryDateWiseContainer from "../view/RemoteSales/SecondarySales/SecondaryDateWiseContainer";
import SecondarySalesCumaSoWiseContainer from "../view/RemoteSales/SecondarySales/SecondarySalesCumaSoWiseContainer";
import PrimarySalesCumalitiveContainer from "../view/RemoteSales/BreadBurnRemoteSales/PrimarySalesCumalitiveContainer";
import SecondarySalesSkuWiseReport from "../information/components/SecodarySales/SecondarySalesSkuWiseReport";
import CorporateSalesContainer from "../view/RemoteSales/RemoteSales/CorporateSalesContainer";
import CorporateSalesListContainer from "../view/RemoteSales/RemoteSales/CorporateSalesListContainer";
import CorporateSalesEditContainer from "../view/RemoteSales/RemoteSales/CorporateSalesEditContainer";
import SecondarySalesReportsContainer from "../view/RemoteSales/SecondarySales/SecondarySalesReportsContainer";
import SecondarySalesSoProductContainer from "../view/RemoteSales/SecondarySales/SecondarySalesSoProductContainer";
import SampleSalesContainer from "../view/RemoteSales/RemoteSales/SampleSalesContainer";
import TradeOfferContainer from "../view/RemoteSales/RemoteSales/TradeOfferContainer";
import SecondaryStock from "../information/components/SecodarySales/SecondaryStock";
import SecondarySalesDemo from "../information/components/SecodarySales/SecondarySalesDemo";
import SaleListDemo from "../information/components/SecodarySales/SaleListDemo";
import PrimarySalesCumaAsmWiseContainer from "../view/RemoteSales/PrimarySales/PrimarySalesCumaAsmWiseContainer";
import PrimarySalesDistributorProductContainer from "../view/RemoteSales/PrimarySales/PrimarySalesDistributorProductContainer";
import DeliveryCumaDistributorWiseContainer from "../view/RemoteSales/PrimarySales/DeliveryCumaDistributorWiseContainer";
import DeliveryDistributorProductContainer from "../view/RemoteSales/PrimarySales/DeliveryDistributorProductContainer";
import SecondarySalesPivotList from "../information/components/RemoteSales/secondarySalesPivot/SecondarySalesPivotList";
import SkuWiseTargetReportContainer from "../view/RemoteSales/Reports/SkuWiseTargetReportContainer";
import DeliveryCumaDistributorWiseBreadBurnContainer from "../view/RemoteSales/PrimarySales/DeliveryCumaDistributorWiseBreadBurnContainer";
import SkuWiseDemandTargetContainer from "../view/RemoteSales/Reports/SkuWiseDemandTargetContainer";
import SampleTableList from "../information/components/Reports/SampleTableList";
import CustomerLedgerContainer from "../view/RemoteSales/Reports/CustomerLedgerContainer";
import CustomerLedgerReport from "../information/components/Reports/CustomerLedgerReport";
import RolePermissionCreate from "../../role-permission-management/information/components/RolePermissionCreate";
import RolePermissionList from "../../role-permission-management/information/components/RolePermissionList";
import NewUser from "../../role-permission-management/information/components/NewUser";
import SecondarySalesStockContainer from "../view/RemoteSales/SecondarySales/SecondarySalesStockContainer";
import DeliveryWIseSkuByDelContainer from "../view/RemoteSales/PrimarySales/DeliveryWIseSkuByDelContainer";
import SecondarySalesSoProductFebs from "../information/components/SecodarySales/SecondarySalesSoProductFebs";
import SecondarySalesFebEdit from "../information/components/SecodarySales/SecondarySalesFebEdit";
import AsmWiseSecondaryTarget from "../information/components/Reports/AsmWiseSecondaryTarget";
import SeconSoWiseContainer from "../view/RemoteSales/SecondarySales/SeconSoWiseContainer";
import SecondarySoAchivementContainer from "../view/RemoteSales/SecondarySales/SecondarySoAchivementContainer";
import SecondarySalesStatusContainer from "../view/RemoteSales/SecondarySales/SecondarySalesStatusContainer";
import SecondarySalesApprovedDelate from "../information/components/RemoteSales/secondarySalesPivot/SecondarySalesApprovedDelate";
import SeconarySalesDistributorStock from "../information/components/SecodarySales/SeconarySalesDistributorStock";
import PrimarySalesSkuWiseDemand from "../information/components/Reports/PrimarySalesSkuWiseDemand";
import PrimarySalesDemdListCum from "../information/components/Reports/PrimarySalesDemdListCum";
import ApplicationLock from "../information/components/Reports/ApplicationLock";
import SuperDepoDemand from "../information/components/RemoteSales/SuperDepoDemand";
import SuperDisDemandList from "../information/components/RemoteSales/SuperDisDemandList";
import SubSalesEdit from "../information/components/RemoteSales/SubSalesEdit";
import SubSalesApprovedReport from "../information/components/RemoteSales/SalesApprovedReport/SubSalesApprovedReport";

import SoSecondarySales from "../information/components/SecodarySales/SoSecondarySales";
import SubDepoDelivery from "../information/components/SecodarySales/SubDepoDelivery";
import SoSKUReport from "../information/components/SecodarySales/SoSKUReport";
import SecondarySalesSoFlat from "../information/components/SecodarySales/SecondarySalesSoFlat";
import SecondarySalesUnapprovedList from "../information/components/SecodarySales/SecondarySalesUnapprovedList";
import AfmlProductLiftingByDistributorContainer from "../view/RemoteSales/SecondarySales/AfmlProductLiftingByDistributorContainer";
import DistributorWiseProductSkuDetailsContainer from "../view/RemoteSales/SecondarySales/DistributorWiseProductSkuDetailsContainer";
import FundCollectionContainer from "../view/RemoteSales/SecondarySales/FundCollectionContainer";
import SecondarySalesSoReportContainer from "../view/RemoteSales/SecondarySales/SecondarySalesSoReportContainer";
import FundListContainer from "../view/RemoteSales/SecondarySales/FundListContainer";
import WorkingDay from "../information/components/SecodarySales/WorkingDay";
import WorkingDaysListContainer from "../view/RemoteSales/SecondarySales/WorkingDaysListContainer";
import GiftAddContainer from "../view/RemoteSales/RemoteSales/GiftAddContainer";
import GiftListContainer from "../view/RemoteSales/RemoteSales/GiftListContainer";
import PrimaryLiftingAllZoneContainer from "../view/RemoteSales/PrimarySales/PrimaryLiftingAllZoneContainer";
import SkuWisePrimaryCumalativeReportContainer from "../view/RemoteSales/PrimarySales/SkuWisePrimaryCumalativeReportContainer";
import DamageDemandEntryContainer from "../view/RemoteSales/RemoteSales/DamageDemandEntryContainer";
import DamageDemandApproveListContainer from "../view/RemoteSales/DamageDemandApproveListContainer";
import DamageDemandlistContainer from "../view/RemoteSales/DamageDemandlistContainer";
import PrimaryDemandStatusContainer from "../view/RemoteSales/PrimarySales/PrimaryDemandStatusContainer";
import { GetMenuListsByPermission } from "../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";

import ProfileContainer from "../../ProfileUpdate/ProfileContainer";
import DashboardChartContainer from "../information/components/Dashboard/DashboardChartContainer";
import SmsSystemContainer from "../information/components/Sms/SmsSystemContainer";
import PrimaryDamageDemandDistributorWiseContainer from "../view/RemoteSales/SecondarySales/PrimaryDamageDemandDistributorWiseContainer";
import PromotionalItemContainer from "../information/components/RemoteSales/PromotionalItemContainer";
import EventListContainer from "../view/RemoteSales/RemoteSales/EventListContainer";
import DamageEntryContainer from "../view/RemoteSales/RemoteSales/DamageEntryContainer";
import DamageListContainer from "../view/RemoteSales/RemoteSales/DamageListContainer";
import DamageApprovedListContainer from "../view/RemoteSales/RemoteSales/DamageApprovedListContainer";
import DamageEditContainer from "../information/components/RemoteSales/DamageEditContainer";
import DamageApproveContainer from "../information/components/RemoteSales/DamageApproveContainer";
import DistributorWiseDamageReportContainer from "../information/components/RemoteSales/DistributorWiseDamageReportContainer";
import TotalDamageReportContainer from "../information/components/RemoteSales/TotalDamageReportContainer";
import AiReportContainer from "../information/components/AIReport/Container/AiReportContainer";
import AiReportDelivery from "../information/components/AIReport/Component/AiReportDelivery";
import StrikeRateOfSecondaryContainer from "../information/components/SecodarySales/StrikeRateOfSecondaryContainer";
import DamageSkuWiseListForAccountsContainer from "../information/components/SecodarySales/DamageSkuWiseListForAccountsContainer";
import StrikeRateOfSecondaryForSoContainer from "../information/components/SecodarySales/StrikeRateOfSecondaryForSoContainer";
import SoTracking from "../../maps/SoTracking";
import SummerySheetReportContainer from "../information/components/RemoteSales/SummerySheetReportContainer";
import SummarySheetAiReport from "../information/components/RemoteSales/SummarySheetAiReport";
import CorporateSalesContainerForMan from "../view/RemoteSales/RemoteSales/CorporateSalesContainerForMan";
import DeliveryConfirmStatusContainer from "../view/RemoteSales/PrimarySales/DeliveryConfirmStatusContainer";
import SODeliveryConfirmStatusContainer from "../view/RemoteSales/PrimarySales/SODeliveryConfirmStatusContainer";
import DamageSumCategoryWiseReportContainer from "../information/components/RemoteSales/DamageSumCategoryWiseReportContainer";
import PriDisWiseVirticleReport from "../information/components/RemoteSales/PriDisWiseVirticleReport";
import CoorporateSalesAddressEntry from "../information/components/RemoteSales/CoorporateSalesAddressEntry";
import SampleReport from "../information/components/RemoteSales/SampleReport";
import TopLevelDashboard from "../information/components/Dashboard/TopLevelDashboard";
import ProductWiseTargetContainer from "../view/RemoteSales/SecondarySales/ProductWiseTargetContainer";
import DemoTablePDF from "../information/components/SecodarySales/DemoTablePDF";
import SalesPersonWiseTA from "../information/components/SecodarySales/SalesPersonWiseTA";
import TradeDemandEntryContainer from "../view/RemoteSales/RemoteSales/TradeDemandEntryContainer";
import SalesPersonWiseTarget from "../information/components/SecodarySales/SalesPersonWiseTarget";
import DamageSumCatWiseForN from "../information/components/RemoteSales/DamageSumCatWiseForN";
import DamageSummaryReportSKUwise from "../information/components/RemoteSales/DamageSummaryReportSKUwise";
import SubDistributorEntry from "../information/components/RemoteSales/SubDistributorEntry";
import TargetVsAchDispointWiseContainer from "../view/RemoteSales/Reports/TargetVsAchDispointWiseContainer";
import SecondarySaleStockNew from "../information/components/SecodarySales/SecondarySaleStockNew";
import SecondarySalesStockModified from "../information/components/SecodarySales/SecondarySalesStockModified";
import TargetVsAchProductWise from "../information/components/Reports/TargetVsAchProductWise";
import PrimarySalesAllZone from "../information/components/SecodarySales/PrimarySalesAllZone";
import TargetVsAcheivementDispointWisePrimarySales from "../information/components/Reports/TargetVsAcheivementDispointWisePrimarySales";
import AfmlPrimarySalesByDistributor from "../information/components/SecodarySales/AfmlPrimarySalesByDistributor";
import TarVSacvZDATwise from "../view/RemoteSales/Reports/TarVSacvZDATwise";
import TarVSacvZDATwiseTSMwise from "../view/RemoteSales/Reports/TarVSacvZDATwiseTSMwise";
import TarVSacvZDATwiseRMMwise from "../view/RemoteSales/Reports/TarVSacvZDATwiseRMMwise";
import TarVSacvZDATwiseDSMwise from "../view/RemoteSales/Reports/TarVSacvZDATwiseDSMwise";
import SoSecondarySalesNew from "../information/components/SecodarySales/SoSecondarySalesNew";


//import SecondarySalesUnapprovedList from "../../../information/components/SecodarySales/SecondarySalesUnapprovedList";
import SattlementDamageEntry from './../information/components/RemoteSales/SattlementDamageEntry';
import SattlementDamageList from "../information/components/RemoteSales/SattlementDamageList";
import SattlementDamageEdit from "../information/components/RemoteSales/SattlementDamageEdit";
import PrimarySalesProductLiftingReportPvt from "../information/components/SecodarySales/PrimarySalesProductLiftingReportPvt";
import PrimarySalesAllZoneWithDelivery from "../information/components/SecodarySales/PrimarySalesAllZoneWithDelivery";
import ApprovedSummaryDamageReportSKUwise from "../information/components/RemoteSales/ApprovedSummaryDamageReportSKUwise";
import PrimaryLiftingDistributorWiseWithTime from "../information/components/SecodarySales/PrimaryLiftingDistributorWiseWithTime";
import PrimaryDamageLiftingDistributorWiseWithTime from "../information/components/SecodarySales/PrimaryDamageLiftingDistributorWiseWithTime";
import RemoteSalesAddContainer from "../view/RemoteSales/RemoteSales/RemoteSalesAddContainer";
import SattlementDamageSummaryReport from "../information/components/RemoteSales/SattlementDamageSummaryReport";
import SattlementDamageForApproved from "../information/components/RemoteSales/SattlementDamageForApproved";
import SattlementDamageSumCatWiseForN from "../information/components/RemoteSales/SattlementDamageSumCatWiseForN";
import SattlementDamageSumCatWiseForY from "../information/components/RemoteSales/SattlementDamageSumCatWiseForY";
import RemoteAppUser from "../../role-permission-management/information/components/RemoteAppUser";
import TarVSacvZDATwiseTSMwiseBakemans from "../view/RemoteSales/Reports/TarVSacvZDATwiseTSMwiseBakemans";
import DisRemoteSalesAdd from "../information/components/RemoteSales/DisRemoteSalesAdd";
import DisRemoteSalesList from "../information/components/RemoteSales/DisRemoteSalesList";
import DisSuperDepoDemand from "../information/components/RemoteSales/DisSuperDepoDemand";
import DisSuperDisDemandList from "../information/components/RemoteSales/DisSuperDisDemandList";
import DisSubSalesApprovedReport from "../information/components/RemoteSales/DisSubSalesApprovedReport";
import DivisionWiseDashboard from "../view/RemoteSales/Reports/DivisionWiseDashboard";
import TarVSacvZDATwiseBakemansRMwise from "../view/RemoteSales/Reports/TarVSacvZDATwiseBakemansRMwise";
import SecondarySalesSoProductV2 from "../information/components/SecodarySales/SecondarySalesSoProductV2";
import RemoteSalesAddExport from "../information/components/RemoteSales/RemoteSalesAddExport";
import RemoteSalesListExport from "../information/components/RemoteSales/RemoteSalesListExport";
import RemoteSalesEditExport from "../information/components/RemoteSales/RemoteSalesEditExport";
import MenuPermission from "../../../_metronic/layout/components/aside/aside-menu/MenuPermission";
import SuperDepoDamageDemandEntry from "../information/components/RemoteSales/SuperDepoDamageDemandEntry";





const routeItems = [

  {
    path: "/zone/list",
    name: "zone list",
    component: ZoneListContainer,
    exact: true,
  },
  {
    path: "/zone/add",
    name: "item add",
    component: ZoneAddContainer,
    exact: true,
  },
  {
    path: "/area/list",
    name: "area list",
    component: AreaListContainer,
    exact: true,
  },
  {
    path: "/area/add",
    name: "item add",
    component: AreaAddContainer,
    exact: true,
  },
  {
    path: "/distribution/list",
    name: "area list",
    component: AreaListContainer,
    exact: true,
  },
  {
    path: "/distribution/add",
    name: "item add",
    component: AreaAddContainer,
    exact: true,
  },
  {
    path: "/territory/list",
    name: "area list",
    component: TerritoryListContainer,
    exact: true,
  },
  {
    path: "/territory/add",
    name: "item add",
    component: TerritoryAddContainer,
    exact: true,
  },
  {
    path: "/region/list",
    name: "area list",
    component: RegionListContainer,
    exact: true,
  },
  {
    path: "/region/add",
    name: "item add",
    component: RegionAddContainer,
    exact: true,
  },
  {
    path: "/remoteSales/list",
    name: "area list",
    component: RemoteSalesListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/export-list",
    name: "area list",
    component: RemoteSalesListExport,
    exact: true,
  },
  {
    path: "/remoteSales/DisRemoteSalesList",
    name: "DisRemoteSalesList",
    component: DisRemoteSalesList,
    exact: true,
  },
  {
    path: "/corporateSales/list",
    name: "area list",
    component: CorporateSalesListContainer,
    exact: true,
  },
  {
    path: "/salesapproved/list",
    name: "Sales approved",
    component: SalesApprovedReportListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/customerProductDelivery",
    name: "delivery list",
    component:DistributorProductDeliverListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/add",
    name: "item add",
    component: RemoteSalesAddContainer,
    exact: true,
  },
  {
    path: "/remoteSales/export",
    name: "Export item add",
    component: RemoteSalesAddExport,
    exact: true,
  },
  {
    path: "/remoteSales/DisRemoteSalesAdd",
    name: "Distributor DisRemoteSalesAdd",
    component: DisRemoteSalesAdd,
    exact: true,
  },
  {
    path: "/remoteSales/corporateSalesEntry",
    name: "item add",
    component: CorporateSalesContainer,
    exact: true,
  },
  {
    path: "/remoteSales/profile/update",
    name: "profile update",
    component: ProfileScreen,
    exact: true,
  },
  {
    path: "/secondarySales/skuwise",
    name: "profile update",
    component: SecondarySalesSkuWiseReport,
    exact: true,
  },
  // {
  //   path: "/remoteSales/edit/:id",
  //   name: "item add",
  //   component: RemoteSalesEditContainer,
  //   exact: true,
  // },
  {
    path: "/remoteSales/edit/:id/:name/:code",
    name: "item add",
    component: RemoteSalesEditContainer,
    exact: true,
  },
  {
    path: "/remoteSales/export-edit/:id/:name/:code",
    name: "export-edit",
    component: RemoteSalesEditExport,
    exact: true,
  },
  {
    path: "/corporateSales/edit/:id/:name/:code",
    name: "item add",
    component: CorporateSalesEditContainer,
    exact: true,
  },
  {
    path: "/secondary/edit/:id/:name/",
    name: "item add",
    component: SecondarySalesEdit,
    exact: true,
  },
  {
    path: "/secondaryFeb/edit/:id/:name/",
    name: "item add",
    component: SecondarySalesFebEdit,
    exact: true,
  },
  {
    path: "/remoteSales/distributorReports",
    name: "Reports",
    component: DistributorReportsContainer,
    exact: true,
  },
  {
    path: "/remoteSales/distributorReportsBR",
    name: "Reports",
    component: DistributorReportsBrContainer,
    exact: true,
  },
  {
    path: "/remoteSales/salesReportDrillSystem",
    name: "Reports",
    component: DistributorReportsDrillContainer,
    exact: true,
  },
  {
    path: "/remoteSales/salesListReport",
    name: "Reports",
    component: SalesReportsListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/secondarySalesList",
    name: "Reports",
    component: SecondarySalesListContainer,
    exact: true,
  },
  {
    path: "/primaryDemand/asmWise",
    name: "secondary Sales",
    component: PrimarySalesCumaAsmWiseContainer,
    exact: true,
  },
  {
    path: "/primaryDemand/distributorSkuWise",
    name: "secondary Sales",
    component: PrimarySalesDistributorProductContainer,
    exact: true,
  },
  {
    path: "/delivery/distributorWise",
    name: "secondary Sales",
    component: DeliveryCumaDistributorWiseContainer,
    exact: true,
  },
  {
    path: "/delivery/BreadBurn",
    name: "secondary Sales",
    component: DeliveryCumaDistributorWiseBreadBurnContainer,
    exact: true,
  },
  {
    path: "/delivery/distributorSkuWise",
    name: "secondary Sales",
    component: DeliveryDistributorProductContainer,
    exact: true,
  },
  {
    path: "/remoteSales/secondarySalespivot",
    name: "Reports",
    component: SecondarySalesPivotContainer,
    exact: true,
  },
  {
    path: "/remoteSales/secondaryApprovedPage",
    name: "Reports",
    component: SecondarySalesPivotPage,
    exact: true,
  },
  // {
  //   path: "/secondarySales/entry",
  //   name: "secondary Sales",
  //   component: SecondarySalesAddContainer,
  //   exact: true,
  // },
  {
    path: "/secondarySales/entry",
    name: "secondary Sales",
    component: SecondarySalesAddContainer,
    exact: true,
  },
  {
    path: "/secondarySales/List",
    name: "secondary Sales",
    component: SecondaryListContainer,
    exact: true,
  },
  {
    path: "/secondarySales/cumalative",
    name: "secondary Sales",
    component: SecondaryCumalativeContainer, 
    exact: true,
  },
  {
    path: "/secondarySales/dateWise",
    name: "secondary Sales",
    component: SecondaryDateWiseContainer,
    exact: true,
  },
  {
    path: "/secondarySales/soWise",
    name: "secondary Sales",
    component: SecondarySalesCumaSoWiseContainer,
    exact: true,
  },

  {
    path: "/primarySales/cumalative",
    name: "secondary Sales",
    component: PrimarySalesCumalitiveContainer,
    exact: true,
  },
  {
    path: "/secondarySales/skuReports",
    name: "secondary Sales",
    component: SecondarySalesReportsContainer,
    exact: true,
  },
  {
    path: "/secondarySales/soProduct",
    name: "secondary Sales",
    component: SecondarySalesSoProductContainer,
    exact: true,
  },
  {
    path: "/secondarySales/soFlatProductReport",
    name: "secondary Sales",
    component: SecondarySalesSoFlat,
    exact: true,
  },
  {
    path: "/secondarySales/soProductFeb",
    name: "secondary Sales",
    component: SecondarySalesSoProductFebs,
    exact: true,
  },
  {
    path: "/primarySales/sample",
    name: "Sample sales",
    component: SampleSalesContainer,
    exact: true,
  },
  {
    path: "/tradeOffer/sales",
    name: "Trade sales",
    component: TradeOfferContainer,
    exact: true,
  },
  {
    path: "/stock/sales",
    name: "SecondaryStock",
    component: SecondaryStock,
    exact: true,
  },
  {
    path: "/secondarySales/demo",
    name: "SecondaryStock",
    component: SecondarySalesDemo,
    exact: true,
  },
  {
    path: "/secondarySales/demoList",
    name: "SecondaryStock",
    component: SaleListDemo,
    exact: true,
  },
  {
    path: "/pivot",
    name: "SecondaryStock",
    component: SecondarySalesPivotList,
    exact: true,
  },
  {
    path: "/skuwiseTargetReport",
    name: "sku wise Target",
    component: SkuWiseTargetReportContainer,
    exact: true,
  },
  {
    path: "/skuwiseDemandWiseTargetReport",
    name: "sku wise Target",
    component: SkuWiseDemandTargetContainer,
    exact: true,
  },
  {
    path: "/targetvsAchDispointWise",
    name: "targetvsAchDispointWise",
    component: TargetVsAchDispointWiseContainer,
    exact: true,
  },
  {
    path: "/secondary/stock",
    name: "sku wise Target",
    component: SecondarySalesStockContainer,
    exact: true,
  },
  {
    path: "/sample",
    name: "sku wise Target",
    component: SampleTableList,
    exact: true,
  },
  {
    path: "/customerLedger",
    name: "customer ledger",
    component: CustomerLedgerReport,
    exact: true,
  },
  {
    path: "/userManagement",
    name: "customer ledger",
    component: NewUser,
    exact: true,
  },
  {
    path: "/deliverySkuByDeliveryDate",
    name: "customer ledger",
    component: DeliveryWIseSkuByDelContainer,
    exact: true,
  },
  {
    path: "/secondaryAsmAchivementReport",
    name: "customer ledger",
    component: AsmWiseSecondaryTarget,
    exact: true,
  },
  {
    path: "/secondarySalesAchivement/sowise",
    name: "secondary Sales",
    component: SeconSoWiseContainer,
    exact: true,
  },
  {
    path: "/secondarySales/soWiseAchivement",
    name: "secondary Sales",
    component: SecondarySoAchivementContainer,
    exact: true,
  },
  {
    path: "/secondarySales/salesStatus",
    name: "secondary Sales",
    component: SecondarySalesStatusContainer,
    exact: true,
  },
  {
    path: "/secondarySales/approvedDelate",
    name: "Reports",
    component: SecondarySalesApprovedDelate,
    exact: true,
  },
  {
    path: "/secondarySales/distributorStockPivot",
    name: "Reports",
    component: SeconarySalesDistributorStock,
    exact: true,
  },
  {
    path: "/primary/distributorSkuProduct",
    name: "Reports",
    component: PrimarySalesSkuWiseDemand,
    exact: true,
  },
  {
    path: "/primary/demandReport",
    name: "Reports",
    component: PrimarySalesDemdListCum,
    exact: true,
  },
  {
    path: "/applicationLock",
    name: "Reports",
    component: ApplicationLock,
    exact: true,
  },
  {
    path: "/salesDemandSuperdepo",
    name: "Reports",
    component: SuperDepoDemand,
    exact: true,
  },
  {
    path: "/DisSuperDepoDemand",
    name: "DisSuperDepoDemand",
    component: DisSuperDepoDemand,
    exact: true,
  },
  {
    path: "/superdis/list",
    name: "area list",
    component: SuperDisDemandList,
    exact: true,
  },
  {
    path: "/superdepo/DisSuperDisDemandList",
    name: "DisSuperDisDemandList",
    component: DisSuperDisDemandList,
    exact: true,
  },
  {
    path: "/subSales/edit/:id/:name/:code/:salesGroup/:superDepo/:superdist",
    name: "item add",
    component: SubSalesEdit,
    exact: true,
  },
  {
    path: "/SubSalesApproved/list",
    name: "Sales approved",
    component: SubSalesApprovedReport,
    exact: true,
  },
  {
    path: "/SuperDepo/DisSubSalesApprovedReport",
    name: "DisSubSalesApprovedReport",
    component: DisSubSalesApprovedReport,
    exact: true,
  },
  // {
  //   path: "/soSecondarySales",
  //   name: "Sales approved",
  //   component: SoSecondarySales,
  //   exact: true,
  //   },
  {
    path: "/soSecondarySales",
    name: "Sales approved",
    component: SoSecondarySalesNew,
    exact: true,
    },
  {
    path: "/subdepoDelivery",
    name: "Sales approved",
    component: SubDepoDelivery,
    exact: true,
  },

  {
    path: "/secondaryso/sosku",
    name: "Sales approved",
    component: SoSKUReport,
    exact: true,
  },
  {
    path: "/GetSecondaryUnApprovedList",
    name: "secondary Sales",
    component: SecondarySalesUnapprovedList,
    exact: true,
  },
  {
    path: "/secondarySales/AfmlProductLiftingByDistributor",
    name: "secondary Sales",
    component: AfmlProductLiftingByDistributorContainer, 
    exact: true,
  },
  {
    path: "/PrimarySales/PrimarySalesByDistributor",
    name: "Primary Sales",
    component: AfmlPrimarySalesByDistributor, 
    exact: true,
  },
  {
    path: "/secondarySales/DistributorWiseProductSkuDetails",
    name: "secondary Sales",
    component: DistributorWiseProductSkuDetailsContainer,
    exact: true,
  },
  {
    path: "/secondarySales/FundCollection",
    name: "secondary Sales",
    component: FundCollectionContainer,
    exact: true,
  },
  {
    path: "/secondarySales/SecondarySalesSoReport",
    name: "secondary Sales",
    component: SecondarySalesSoReportContainer,
    exact: true,
  },
  {
    path: "/secondarySales/FundList",
    name: "secondary Sales",
    component: FundListContainer,
    exact: true,
  },
  {
    path: "/secondarySales/MonthWorkingDay",
    name: "secondary Sales",
    component: WorkingDay,
    exact: true,
  },
  {
    path: "/secondarySales/WorkingDaysList",
    name: "secondary Sales",
    component: WorkingDaysListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/gift",
    name: "gift add",
    component: GiftAddContainer,
    exact: true,
  },
  {
    path: "/remoteSales/giftlist",
    name: "gift list",
    component:GiftListContainer,
    exact: true,
  },
  {
    path: "/delivery/primaryLiftingAllZone",
    name: "secondary Sales",
    component: PrimaryLiftingAllZoneContainer,
    exact: true,
  },
  {
    path: "/delivery/SkuWisePrimaryCumalativeReport",
    name: "secondary Sales",
    component: SkuWisePrimaryCumalativeReportContainer,
    exact: true,
  },
  {
    path: "/remoteSales/DamageDemandEntry",
    name: "item add",
    component: DamageDemandEntryContainer,
    exact: true,
  },
  {
    path: "/superdepo/DamageDemandEntry",
    name: "item add",
    component: SuperDepoDamageDemandEntry,
    exact: true,
  },
  {
    path: "/salesapproved/DamageDemandApproveList",
    name: "damage approved",
    component: DamageDemandApproveListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/DamageDemandlist",
    name: "damage list",
    component: DamageDemandlistContainer,
    exact: true,
  },
  {
    path: "/delivery/PrimaryDemandStatus",
    name: "Primary Demand",
    component: PrimaryDemandStatusContainer,
    exact: true,
  },
  {
    path: "/ProfileUpdate/Profile",
    name: "Profile",
    component: ProfileContainer,
    exact: true,
  },
  {
    path: "/SPerformanceDashboard",
    name: "SPerformanceDashboard",
    component: DashboardChartContainer,
    exact: true,
  },
  {
    path: "/SMSSystem/Sms",
    name: "Sms",
    component: SmsSystemContainer,
    exact: true,
  },
  {
    path: "/primary/PrimaryDamageDemandDistributorWise",
    name: "PrimaryDamageDemand",
    component: PrimaryDamageDemandDistributorWiseContainer,
    exact: true,
  },
  {
    path: "/Promotional/PromotionalItem",
    name: "PromotionalItem",
    component: PromotionalItemContainer,
    exact: true,
  },
  {
    path: "/Promotional/EventList",
    name: "EventList",
    component: EventListContainer,
    exact: true,
  },
  {
    path: "/Damage/DamageEntry",
    name: "Damaged",
    component: DamageEntryContainer,
    exact: true,
  },
  {
    path: "/Damage/DamageList",
    name: "Damage List",
    component: DamageListContainer,
    exact: true,
  },
  {
    path: "/Damage/DamageApprovedList",
    name: "Damage Approved List",
    component: DamageApprovedListContainer,
    exact: true,
  },
  {
    path: "/remoteSales/damageedit/:id/:name/:code",
    name: "damage add",
    component: DamageEditContainer,
    exact: true,
  },
  
  {
    path: "/remoteSales/damageApproved",
    name: "damage add",
    component: DamageApproveContainer,
    exact: true,
  },
  {
    path: "/remoteSales/distributorWiseDamageReport",
    name: "damage report distributor wise",
    component: DistributorWiseDamageReportContainer,
    exact: true,
  },
  {
    path: "/remoteSales/DamageSummeryReport",
    name: "damage report distributor wise",
    component: TotalDamageReportContainer,
    exact: true,
  },
  {
    path: "/remoteSales/aiReport",
    name: "damage report distributor wise",
    component: AiReportDelivery,
    exact: true,
  },
  {
    path: "/remoteSales/StrikeRateOfSecondary",
    name: "StrikeRateOfSecondary",
    component: StrikeRateOfSecondaryContainer,
    exact: true,
  },
  {
    path: "/remoteSales/DamageSkuWiseListForAccounts",
    name: "DamageSkuWiseListForAccounts",
    component: DamageSkuWiseListForAccountsContainer,
    exact: true,
  },
  {
    path: "/remoteSales/StrikeRateOfSecondaryForSo",
    name: "StrikeRateOfSecondaryForSo",
    component: StrikeRateOfSecondaryForSoContainer,
    exact: true,
  },
  {
    path: "/remoteSales/SummerySheetReport",
    name: "SummerySheetReport",
    component: SummerySheetReportContainer,
    exact: true,
  },
  {
    path: "/maps/soTrackingSystem",
    name: "soTrackingSystem",
    component: SoTracking,
    exact: true,
  },
  {
    path: "/remoteSales/SummerySheetAiReport",
    name: "SummerySheetAiReport",
    component: SummarySheetAiReport,
    exact: true,
  },
  {
    path: "/remoteSales/CoorporateSales",
    name: "CoorporateSales",
    component: CorporateSalesContainerForMan,
    exact: true,
  },
  {
    path: "/remoteSales/DeliveryConfirmStatus",
    name: "Primary",
    component: DeliveryConfirmStatusContainer,
    exact: true,
  },
  {
    path: "/remoteSales/SODeliveryConfirmStatus",
    name: "Primary",
    component: SODeliveryConfirmStatusContainer,
    exact: true,
  },
  {
    path: "/remoteSales/DamageSummeryReportCategoryWise",
    name: "DamageSummeryReportCategoryWise",
    component: DamageSumCategoryWiseReportContainer,
    exact: true,
  },
  {
    path: "/remoteSales/PrimaryDeliveryDistributorWithProduct",
    name: "PrimaryDeliveryDistributorWithProduct",
    component: PriDisWiseVirticleReport,
    exact: true,
  },
  {
    path: "/remoteSales/CoorporateSalesDisAddressEntry",
    name: "CoorporateSalesDisAddressEntry",
    component: CoorporateSalesAddressEntry,
    exact: true,
  },
  {
    path: "/remoteSales/TopLevelDashboardview",
    name: "TopLevelDashboard",
    component: TopLevelDashboard,
    exact: true,
  },
  {
    path: "/remoteSales/ProductWiseTarget",
    name: "ProductWiseTarget",
    component: DemoTablePDF,
    exact: true,
  },
  {
    path: "/remoteSales/SalesPersonWiseTA",
    name: "SalesPersonWiseTAContainer",
    component: SalesPersonWiseTA,
    exact: true,
  },
  {
    path: "/remoteSales/TradeDemandEntry",
    name: "TradeDemandEntry",
    component: TradeDemandEntryContainer,
    exact: true,
  },
  {
    path: "/remoteSales/SalesPersonWiseTarget",
    name: "SalesPersonWiseTarget",
    component: SalesPersonWiseTarget,
    exact: true,
  },
  {
    path: "/remoteSales/DamageSumCatWiseForN",
    name: "DamageSumCatWiseForN",
    component: DamageSumCatWiseForN,
    exact: true,
  },
  {
    path: "/remoteSales/DamageSummaryReportSKUwise",
    name: "DamageSummaryReportSKUwise",
    component: DamageSummaryReportSKUwise,
    exact: true,
  },
  {
    path: "/remoteSales/SubDistributorEntry",
    name: "SubDistributorEntry",
    component: SubDistributorEntry,
    exact: true,
  },
  {
    path: "/secondary/SecondarySaleStockNew",
    name: "SecondarySaleStock",
    component: SecondarySaleStockNew,
    exact: true,
  },
  {
    path: "/secondary/SecondarySalesStockModified",
    name: "SecondarySalesStockModified",
    component: SecondarySalesStockModified,
    exact: true,
  },
  {
    path: "/secondary/TargetVsAchProductWise",
    name: "TargetVsAchProductWise",
    component: TargetVsAchProductWise,
    exact: true,
  },
  {
    path: "/Primary/PrimarySalesAllZone",
    name: "PrimarySalesAllZone",
    component: PrimarySalesAllZone,
    exact: true,
  },
  {
    path: "/Primary/PrimarySalesAllZoneWithDelivery",
    name: "PrimarySalesAllZoneWithDelivery",
    component: PrimarySalesAllZoneWithDelivery,
    exact: true,
  },
  {
    path: "/Primary/TargetVsAcheivementDispointWisePrimarySales",
    name: "TargetVsAcheivementDispointWisePrimarySales",
    component: TargetVsAcheivementDispointWisePrimarySales,
    exact: true,
  },
  {
    path: "/Primary/TarVSacvZDATwise",
    name: "TarVSacvZDATwise",
    component: TarVSacvZDATwise,
    exact: true,
  },
  {
    path: "/Primary/DivisionWiseDashboard",
    name: "DivisionWiseDashboard",
    component: DivisionWiseDashboard,
    exact: true,
  },
  {
    path: "/secondary/TarVSacvZDATwiseTSMwise",
    name: "TarVSacvZDATwiseTSMwise",
    component: TarVSacvZDATwiseTSMwise,
    exact: true,
  },
  {
    path: "/secondary/TarVSacvZDATwiseTSMwiseBakemans",
    name: "TarVSacvZDATwiseTSMwiseBakemans",
    component: TarVSacvZDATwiseTSMwiseBakemans,
    exact: true,
  },
  {
    path: "/secondary/TarVSacvZDATwiseRMwise",
    name: "TarVSacvZDATwiseRMMwise",
    component: TarVSacvZDATwiseRMMwise,
    exact: true,
  },
  {
    path: "/secondary/TarVSacvZDATwiseDSMwise",
    name: "TarVSacvZDATwiseDSMwise",
    component: TarVSacvZDATwiseDSMwise,
    exact: true,
  },
  {
    path: "/secondary/SattlementDamageEntry",
    name: "SattlementDamageEntry",
    component: SattlementDamageEntry,
    exact: true,
  },
  {
    path: "/secondary/SattlementDamageList",
    name: "SattlementDamageList",
    component: SattlementDamageList,
    exact: true,
  },
  {
    path: "/remoteSales/SattlementDamageEdit/:id/:name/:code",
    name: "SattlementDamageEdit",
    component: SattlementDamageEdit,
    exact: true,
  },
  {
    path: "/PrimarySalesProductLiftingReportPvt",
    name: "PrimarySalesProductLiftingReportPvt",
    component: PrimarySalesProductLiftingReportPvt,
    exact: true,
  },
  {
    path: "/ApprovedSummaryDamageReportSKUwise",
    name: "ApprovedSummaryDamageReportSKUwise",
    component: ApprovedSummaryDamageReportSKUwise,
    exact: true,
  },
  
  {
    path: "/PrimaryLiftingDistributorWiseWithTime",
    name: "PrimaryLiftingDistributorWiseWithTime",
    component: PrimaryLiftingDistributorWiseWithTime,
    exact: true,
  },
  {
    path: "/PrimaryDamageLiftingDistributorWiseWithTime",
    name: "PrimaryDamageLiftingDistributorWiseWithTime",
    component: PrimaryDamageLiftingDistributorWiseWithTime,
    exact: true,
  },
  {
    path: "/SattlementDamageSummaryReport",
    name: "SattlementDamageSummaryReport",
    component: SattlementDamageSummaryReport,
    exact: true,
  },
  {
    path: "/SattlementDamageForApproved",
    name: "SattlementDamageForApproved",
    component: SattlementDamageForApproved,
    exact: true,
  },
  {
    path: "/SattlementDamageSumCatWiseForN",
    name: "SattlementDamageSumCatWiseForN",
    component: SattlementDamageSumCatWiseForN,
    exact: true,
  },
  {
    path: "/SattlementDamageSumCatWiseForY",
    name: "SattlementDamageSumCatWiseForY",
    component: SattlementDamageSumCatWiseForY,
    exact: true,
  },
  {
    path: "/RemoteAppUser",
    name: "RemoteAppUser",
    component: RemoteAppUser,
    exact: true,
  },
  {
    path: "/TarVSacvZDATwiseBakemansRMwise",
    name: "TarVSacvZDATwiseBakemansRMwise",
    component: TarVSacvZDATwiseBakemansRMwise,
    exact: true,
  },
  {
    path: "/SecondarySalesSoProductV2",
    name: "SecondarySalesSoProductV2",
    component: SecondarySalesSoProductV2,
    exact: true,
  },
  {
    path: "/menu/MenuPermission",
    name: "MenuPermission",
    component: MenuPermission,
    exact: true,
  }
  
];

function getSalesRoutes() {
  {

    return routeItems.map((route, index) => (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getSalesRoutes;
