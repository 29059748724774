import { indexOf } from "lodash";
import * as Types from "../../types/SecondarySalesTypes";

const initialState = {
  targetDate: [],
  DispointTarget: [],
  allItemReports: [],
  lifting: [],
  DisPointLoading: false,
  DispointTargetSales: [],
  DisPointLoadingSales: false,
  allItemReportsSales: [],

  totalData: {

    biscuitDifferentPrice: 0,
    biscuitDifferentQty: 0,
    biscuitLiftingPrice: 0,
    biscuitLiftingQty: 0,
    biscuitTargetPrice: 0,
    biscuitTargetQty: 0,
    cakeDifferentPrice: 0,
    cakeDifferentQty: 0,
    cakeLiftingPrice: 0,
    cakeLiftingQty: 0,
    cakeTargetPrice: 0,
    cakeTargetQty: 0,
    cookisDifferentPrice: 0,
    cookisDifferentQty: 0,
    cookisLiftingPrice: 0,
    cookisLiftingQty: 0,
    cookisTargetPrice: 0,
    cookisTargetQty: 0,
    cookisandBiscuitDifferentPrice: 0,
    cookisandBiscuitDifferentQty: 0,
    cookisandBiscuitLiftingPrice: 0,
    cookisandBiscuitLiftingQty: 0,
    cookisandBiscuitTargetPrice: 0,
    cookisandBiscuitTargetQty: 0,
    totalDifferentPrice: 0,
    totalDifferentQty: 0,
    totalLiftingPrice: 0,
    totalLiftingQty: 0,
    totalTargetPrice: 0,
    totalTargetQty: 0,
    isLoading: false,
    waferDifferentPrice: 0,
    waferLiftingPrice: 0,
    waferLiftingQty: 0,
    waferTargetPrice: 0,
    waferTargetQty: 0,
    waferDifferentQty: 0,
   snacksDifferentPrice: 0,
   snacksLiftingPrice: 0,
   snacksLiftingQty: 0,
   snacksTargetPrice: 0,
   snacksTargetQty: 0,
   snacksDifferentQty: 0,
  },
  zoneReports: [],
  zoneReportsLoading: false,
  totalDays: {},
  workingLeft: {},
  divisionReports: [],
  divisionReportsLoading: false,
  areaReports: [],
  areaReportsLoading: false,
  territoryReports: [],
  territoryReportsLoading: false,
  TotalTarget:{},
  filteredDivisionReports: [],
  filteredRegionReports: [],
  filteredTerritoryReports: [],
  bottomTerritoryReports: [],
  bottom10RMReports: [],
  bottom3DSMReports: [],
  territoryActiveInactive:[]

};
const TargetReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case Types.GET_SKU_WISE_TARGET:
      let countSku = action.payload.data.length || Object.keys(action.payload.data).length;
      const paytotal = { ...state.totalData };
      paytotal.biscuitDifferentPrice = action.payload.data.biscuitDifferentPrice;
      paytotal.biscuitDifferentQty = action.payload.data.biscuitDifferentQty;
      paytotal.biscuitLiftingPrice = action.payload.data.biscuitLiftingPrice;
      paytotal.biscuitLiftingQty = action.payload.data.biscuitLiftingQty;
      paytotal.biscuitTargetPrice = action.payload.data.biscuitTargetPrice;
      paytotal.biscuitTargetQty = action.payload.data.biscuitTargetQty;
      paytotal.cakeDifferentPrice = action.payload.data.cakeDifferentPrice;
      paytotal.cakeDifferentQty = action.payload.data.cakeDifferentQty;
      paytotal.cakeLiftingPrice = action.payload.data.cakeLiftingPrice;
      paytotal.cakeLiftingQty = action.payload.data.cakeLiftingQty;
      paytotal.cakeTargetPrice = action.payload.data.cakeTargetPrice;
      paytotal.cakeTargetQty = action.payload.data.cakeTargetQty;
      paytotal.cookisDifferentPrice = action.payload.data.cookisDifferentPrice;
      paytotal.cookisDifferentQty = action.payload.data.cookisDifferentQty;
      paytotal.cookisLiftingPrice = action.payload.data.cookisLiftingPrice;
      paytotal.cookisLiftingQty = action.payload.data.cookisLiftingQty;
      paytotal.cookisTargetPrice = action.payload.data.cookisTargetPrice;
      paytotal.cookisTargetQty = action.payload.data.cookisTargetQty;
      paytotal.cookisandBiscuitDifferentPrice = action.payload.data.cookisandBiscuitDifferentPrice;
      paytotal.cookisandBiscuitDifferentQty = action.payload.data.cookisandBiscuitDifferentQty;
      paytotal.cookisandBiscuitLiftingPrice = action.payload.data.cookisandBiscuitLiftingPrice;
      paytotal.cookisandBiscuitLiftingQty = action.payload.data.cookisandBiscuitLiftingQty;
      paytotal.cookisandBiscuitTargetPrice = action.payload.data.cookisandBiscuitTargetPrice;
      paytotal.cookisandBiscuitTargetQty = action.payload.data.cookisandBiscuitTargetQty;
      paytotal.totalDifferentPrice = action.payload.data.totalDifferentPrice;
      paytotal.totalDifferentQty = action.payload.data.totalDifferentQty;
      paytotal.totalLiftingPrice = action.payload.data.totalLiftingPrice;
      paytotal.totalLiftingQty = action.payload.data.totalLiftingQty;
      paytotal.totalTargetPrice = action.payload.data.totalTargetPrice;
      paytotal.totalTargetQty = action.payload.data.totalTargetQty;

      paytotal.waferDifferentPrice = action.payload.data.waferDifferentPrice;
      paytotal.waferDifferentQty = action.payload.data.waferDifferentQty;
      paytotal.waferLiftingPrice = action.payload.data.waferLiftingPrice;
      paytotal.waferLiftingQty = action.payload.data.waferLiftingQty;
      paytotal.waferTargetPrice = action.payload.data.waferTargetPrice;
      paytotal.waferTargetQty = action.payload.data.waferTargetQty;

      paytotal.snacksDifferentPrice = action.payload.data.snacksDifferentPrice;
      paytotal.snacksDifferentQty = action.payload.data.snacksDifferentQty;
      paytotal.snacksLiftingPrice = action.payload.data.snacksLiftingPrice;
      paytotal.snacksLiftingQty = action.payload.data.snacksLiftingQty;
      paytotal.snacksTargetPrice = action.payload.data.snacksTargetPrice;
      paytotal.snacksTargetQty = action.payload.data.snacksTargetQty;
      return {
        ...state,
        targetDate: countSku > 0 ? action.payload.data.detailsReport : [],
        totalData: paytotal,
        targetLoading: action.payload.isLoading,
      };

    case Types.GET_TARTGET_SALES_CLEAN:
      return {
        ...state,
        targetDate: [],
        totalData: null
      };

    case Types.GET_TARGET_ACV_DISPOINT:
      console.log('targetaction.payload.data', action.payload);
      return {
        ...state,
        DispointTarget: action.payload.data,
        DisPointLoading: action.payload.isLoading,
        allItemReports: action.payload.data,
      };

      case Types.GET_TARGET_ACV_DISPOINT_SALES_WISE:
        console.log('targetaction.payload.data', action.payload);
        return {
          ...state,
          DispointTargetSales: action.payload.data,
          DisPointLoadingSales: action.payload.isLoading,
          allItemReportsSales: action.payload.data,
        };

    case Types.GET_TARGET_ACV_PRODUCT_WISE:
      console.log('action.payload', action.payload);

      // Extract item reports from the payload
      const allItemReports = action.payload.data?.detailsReport;
      const demandItemReports = action.payload.data?.demanReport;
      const targetItemReports = action.payload.data?.targetData;
      const liftingItemReports = action.payload.data?.liftingReport?.allReport;


      const aggregatedReports = {};

      // Check if allItemReports is an object and has properties
      if (typeof allItemReports === 'object' && allItemReports !== null) {
        // Loop through all areas in the item reports
        for (const areaKey in allItemReports) {
          if (allItemReports.hasOwnProperty(areaKey)) {
            const areaReports = allItemReports[areaKey];

            if (areaReports && Array.isArray(areaReports.itemReport)) {
              areaReports.itemReport.forEach((item) => {
                const { prodID, prodName, prodCatagories, liftingPrice, liftingQty } = item;

                if (aggregatedReports[prodID]) {
                  
                  aggregatedReports[prodID].liftingPrice += liftingPrice;
                  aggregatedReports[prodID].liftingQty += liftingQty;
                } else {
                  aggregatedReports[prodID] = {
                    prodID,
                    prodName,
                    prodCatagories,
                    targetPrice: 0,
                    liftingPrice,
                    liftingQty,
                    demandQty: 0, // Initialize demandQty
                    targetQty: 0,

                  };
                }
              });
            }
          }
        }
      }

      // Add demandQty from demandItemReports
      if (demandItemReports && Array.isArray(demandItemReports)) {
        demandItemReports.forEach((demandItem) => {
          const { productID, prodQty } = demandItem;
          if (aggregatedReports[productID]) {
            aggregatedReports[productID].demandQty += prodQty;
          } else {
            aggregatedReports[productID] = {
              prodID: productID,
              demandQty: prodQty,
            };
          }
        });
      }


       // Add targetQty from targetItemReports
       if (targetItemReports && Array.isArray(targetItemReports)) {
        targetItemReports.forEach((taritem) => {
          const { prodID, prodQty,prodPrice } = taritem;
          if (aggregatedReports[prodID]) {
            aggregatedReports[prodID].targetQty += prodQty;
            aggregatedReports[prodID].targetPrice += prodPrice;
          } else {
            aggregatedReports[prodID] = {
              prodID: prodID,
              targetQty: prodQty,
              targetPrice: prodPrice,
            };
          }
        });
      }

      // Convert the aggregatedReports object to an array of values
      const aggregatedReportsArray = Object.values(aggregatedReports);

      return {
        ...state,
        DispointTarget: action.payload.data,
        DisPointLoading: action.payload.isLoading,
        allItemReports: aggregatedReportsArray,
        lifting: liftingItemReports,
      };

      case Types.GET_ZONE_DATA_TARVSACHV:
        
        return {
          ...state,
          zoneReports: action.payload.data?.allData,
          zoneReportsLoading: action.payload.isLoading,
          totalDays: action.payload.data?.totalDays,
          workingLeft: action.payload.data?.workingLeft,
          TotalTarget: action.payload.data?.total_target,
        };

      case Types.GET_DIVISION_DATA_TARVSACHV:
        
        return {
          ...state,
          divisionReports: action.payload.data?.allData,
          divisionReportsLoading: action.payload.isLoading,
        
        };
      case Types.GET_AREA_DATA_TARVSACHV:
        
        return {
          ...state,
          areaReports: action.payload.data?.allData,
          areaReportsLoading: action.payload.isLoading,
        
        };
      case Types.GET_TERRITORY_DATA_TARVSACHV:
        
        return {
          ...state,
          territoryReports: action.payload.data?.allData,
          territoryReportsLoading: action.payload.isLoading,
        
        };

        case Types.SET_LIMITED_DIVISION_REPORTS:
      return {
        ...state,
        filteredDivisionReports: action.payload,
      };
        case Types.SET_LIMITED_REGION_REPORTS:
      return {
        ...state,
        filteredRegionReports: action.payload,
      };
        case Types.SET_LIMITED_TERRITORY_REPORTS:
      return {
        ...state,
        filteredTerritoryReports: action.payload,
      };
      case Types.SET_BOTTOM_TERRITORY_REPORTS:
        return {
          ...state,
          bottomTerritoryReports: action.payload,  // New state property
        };
      case Types.SET_BOTTOM_RM_REPORTS:
        
        return {
          ...state,
          bottom10RMReports: action.payload,  // New state property
        };
      case Types.SET_BOTTOM_3_DSM_REPORTS:
        
        return {
          ...state,
          bottom3DSMReports: action.payload,  // New state property
        };

      case Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE:
        
        return {
          ...state,
          territoryActiveInactive: action.payload.data,
        };



    default:
      break;
  }
  return newState;
};

export default TargetReducer;


