import Axios from "axios";
import * as Types from "../../types/SecondarySalesTypes";

export const getCumalativeReport = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId,SGeoType=2) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST, payload: responseList });

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondarySalaryListByAsm?`;

    if(zoneId !== null){
   
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(terrioryId !== null){
      url +=`&Territory=${terrioryId}`
    }
    if(fromDate !== null){
       
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
        
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
      url +=`&SGeoType=${SGeoType}`
  }
    await Axios.get(url).then((res) => {
      console.log('res', res);
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isLoading = false;

    });
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST, payload: responseList });
  };

  export const getCumalativeReportBySo = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,territoryId=0,SGeoType=2) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO, payload: responseList });

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondaryCumalitiveByso?`;

    if(zoneId !== null){
       
        url +=`ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(territoryId !== null){
        url +=`&Territory=${territoryId}`
    }
    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
       
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
        url +=`&SGeoType=${SGeoType}`
    }

    console.log('url', url);
    await Axios.get(url).then((res) => {
    console.log('res :>> ', res);
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO, payload: responseList });
  };
  export const getSoReport = (fromDate,toDate,zoneId,divisionId,areaId,territoryId,SGeoType) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSoWiseImsReportValue?`;

    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
       
        url +=`&TDate=${toDate}`
    }

    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST, payload: responseList });
  };



  export const getUnapprovedList = (fromDate,toDate,zoneid,divisionid,areaid,territoryId,SGeoType) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSecondaryUnApprovedList?Fdate=${fromDate}&Tdate=${toDate}`;

    if(zoneid !== null){
       
        url +=`&ZoneID=${zoneid}`
    }
    if(divisionid !== null){
        
        url +=`&DivisionID=${divisionid}`
    }
    if(areaid !== null){
        url +=`&AreaID=${areaid}`
    }
    if(territoryId !== null){
        url +=`&Territory=${territoryId}`
    }
    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
       
        url +=`&TDate=${toDate}`
    }

    

    try {
      await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null?[]:res.data.data;
        responseList.status = false;
      });
    } catch (error) {
      
    }

    dispatch({ type: Types.GET_SECONDARY_UNAPPROVED_LIST, payload: responseList });

   
   
  };
  
  export const getPrimaryCumalativeReportByDistributor = (fromDate,toDate,zoneId,divisionId,areaId,SGeoType) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesCumalitiveByDistributor?`;

    if(zoneId !== null){
       
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
       
        url +=`&TDate=${toDate}`
    }

    if(SGeoType !== null){
       
      url +=`&SGeoType=${SGeoType}`
  }

    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO, payload: responseList });
  };
  export const getSecondarySalesStatusReport = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,territoryId=0,reportype=2) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
      dispatch({ type: Types.GET_SECONDARY_STATUS, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOListthatEnterthesecindarysalesornot?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${reportype}`;
   
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SECONDARY_STATUS, payload: responseList });
  };

  
  export const getProductWiseCumalitiveReport = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId=0,distributionPoint=0,SGeoType) => async (dispatch) => {

    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };

   

    dispatch({ type: Types.GET_SKUWISE_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductReportQumelitive?ZoneID=${zoneId}&Territory=${terrioryId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&DistributionPoint=${distributionPoint}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
    await Axios.get(url).then((res) => {
 
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SKUWISE_REPORT, payload: responseList });
  };
  export const getSOSkuProduct = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSoWiseProductSSDetailReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
    await Axios.get(url).then((res) => {
     
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
  };

  export const getSOSkuProductv2 = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_SKU_REPORT_V2, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOWiseProductDetailsReportwithTotalV2?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
    await Axios.get(url).then((res) => {
      console.log('res123 :>> ', res);
     
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT_V2, payload: responseList });
  };

  export const getSOSkuFlatProduct = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_SO_SKU_REPORT_FLAT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSoWiseProductFlatReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}&currentPage=${1}&maxRow=${10}`;
     
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT_FLAT, payload: responseList });
  };

  export const getSoSecondarySku = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSosalesSkuWiseReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
     
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
  };
  // So SKU reporrt by efaj
  export const getSOSkuReport = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSoWiseProductSSDetailReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}`;
     
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
  };
  

  export const getSowiseAchivement = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId=0,sopoint=0) => async (dispatch) => {

    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSSTargetVSAcheivmentSOPointWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&delpoint=${sopoint}&Fdate=${fromDate}&Tdate=${toDate}&Type=1`;
    
     
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
  };
  export const getSowiseAchivementForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId=0) => {

    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSSTargetVSAcheivmentSOPointWiseForExcel?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=1`;
    
     
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  };
  export const getSowiseAchivementReport = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,territoryId=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSSTargetVSAcheivmentSOWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&TerritoryId=${territoryId} &Fdate=${fromDate}&Tdate=${toDate}&Type=1`;
    
     
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
  };
  export const getFebSOSkuProduct = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetFebSoWiseProductSSDetailReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}`;
     
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_SKU_REPORT, payload: responseList });
  };
  export const getDistributorSkuProduct = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_DISTRIBUTOR_SKU_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesDistributorwithProduct?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}`;
     
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DISTRIBUTOR_SKU_REPORT, payload: responseList });
  };
  export const cleanSecondarySalesData = () => async (dispatch) => {
    dispatch({ type: Types.GET_SALES_CLEAN, payload: null });
  };

  export const getAfmlProductLiftingByDistributor = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId,SGeoType) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR, payload: responseList });

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAfmlProductLiftingByDistributor?`;

    if(zoneId !== null){
   
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(terrioryId !== null){
      url +=`&Territory=${terrioryId}`
    }
    if(fromDate !== null){
       
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
        
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
      url +=`&SGeoType=${SGeoType}`
  }
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR, payload: responseList });
  };

  export const GetAfmlPrimarysalesByDistributor = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId,SGeoType) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_AFML_PRIMARY_SALES_BY_DISTRIBUTOR, payload: responseList });

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarysalesByDistributor?`;

    if(zoneId !== null){
   
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(terrioryId !== null){
      url +=`&Territory=${terrioryId}`
    }
    if(fromDate !== null){
       
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
        
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
      url +=`&SGeoType=${SGeoType}`
  }
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_AFML_PRIMARY_SALES_BY_DISTRIBUTOR, payload: responseList });
  };
  export const getProductDamagedLiftingByDistributor = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId,SGeoType,rtype) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR, payload: responseList });

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDamageDemandDistributorWise?`;

    if(zoneId !== null){
   
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(terrioryId !== null){
      url +=`&Territory=${terrioryId}`
    }
    if(fromDate !== null){
       
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
        
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
      url +=`&SGeoType=${SGeoType}`
  }
    if(rtype !== null){
       
      url +=`&reporttype=${rtype}`
  }
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR, payload: responseList });
  };
  export const getAfmlProductLiftingByDistributorForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) =>  {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAfmlProductLiftingByDistributor?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
     await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  
  };
  export const getAfmlProductDamagedLiftingByDistributorForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) =>  {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDamageDemandDistributorWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
     await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  
  };

  export const getDistributorWiseProductSkuDetails = (fromDate,toDate,zoneId,divisionId,areaId,territoryId,SGeoType,reportType) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistributorWiseProductSkuDetails?`;

    if(zoneId !== null){
       
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(territoryId !== null){
        url +=`&Territory=${territoryId}`
    }
    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(toDate !== null){
       
        url +=`&TDate=${toDate}`
    }
    if(SGeoType !== null){
       
        url +=`&SGeoType=${SGeoType}`
    }
    if(reportType !== null){
       
        url +=`&reportType=${reportType}`
    }
    await Axios.get(url).then((res) => {
    
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_DISTRIBUTOR_WISE_PRODUCT_SKU_DETAILS, payload: responseList });
  };

  export const geSalesPersonTA = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId=0) => async (dispatch) => {

    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
  
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSalesPersonSOPointWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=1`;
    
     
    await Axios.get(url).then((res) => {
      console.log('res :>> ', res);
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SO_ACHIVEMENT_REPORT, payload: responseList });
  };

  export const getAfmlPrimarySalesByDistributorForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) =>  {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarysalesByDistributor?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
     await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  
  };