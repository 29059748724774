import React, {useMemo,useState} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";

import {Demo2Dashboard} from "./Demo2Dashboard";
import {Demo3Dashboard} from "./Demo3Dashboard";
import {Demo7Dashboard, ValidationSchemaExample} from "./Demo7Dashboard";
import Demo1Dashboard from "./Demo1Dashboard";
import { getEmployeData } from "../../../app/modules/Auth/_redux/authCrud";
import BreadBurnDashboard from "./BreadBurnDashboard";
import SoDashboard from "./SoDashboard";
import DashboardDemo from "../../../modules/Sales/information/components/Dashboard/DashboardDemo";
import DashboardChartContainer from "../../../modules/Sales/information/components/Dashboard/DashboardChartContainer";
import WelCome from './Welcome';




export function Dashboard() {
    const uiService = useHtmlClassService();
    const data = getEmployeData();
    console.log('data :>> ', data);

    // if (!("Notification" in window)) {
    //     console.log("This browser does not support desktop notification");
    //   } else {
    //     Notification.requestPermission();
    //   }
    //   setInterval(new Notification('This is the test message'), 1000);
        
      
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>
    


{layoutProps.demo === 'demo1' && (
  <>
    {data?.role === 7 && <BreadBurnDashboard />}
    {/* {data?.role === 7 && <WelCome />}
    {data?.role === 39 && <WelCome />} */}
    {data?.role !== 7 && data?.role !== 39 && data?.role !== 10 && <DashboardChartContainer />}
    { data?.role == 10 && <WelCome />}
  </>
)}

        {/* {layoutProps.demo === 'demo1' && <WelCome />} */}
        {layoutProps.demo === 'demo2' && <Demo2Dashboard />}
        {layoutProps.demo === 'demo3' && <Demo3Dashboard />}
        {layoutProps.demo === 'demo4' && <SoDashboard />}
        {/* {layoutProps.demo === 'demo5' && <Demo5Dashboard />} */}
        {layoutProps.demo === 'demo6' && <BreadBurnDashboard />}
        {layoutProps.demo === 'demo7' && <Demo1Dashboard />}
    </>;
}
