import React, { useEffect, useState, useRef,useMemo } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputHandle } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DataTable from "react-data-table-component"
import { getDistributorListPermissionWise, sendSms } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import FilterComponent from "../FilterComponent";

const SmsSystem = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();

    const distibList = useSelector((state) => state.remoteSales.distibListforsms);
    const smsStatus = useSelector((state) => state.remoteSales.smsStatus);
    console.log('smsStatus', smsStatus);

    const [selectedData, setSelectedData] = React.useState(
        [
        
            
    ]
    );
    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [smsText, setSmsText] = useState(null);
    const [selectSmsType, setSelectSmsType] = useState(null);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
      false
    );
    


    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }
    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };
    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const showReport = () => {
        dispatch(getDistributorListPermissionWise(selectZone, selectDivision, selectArea, selectTerritory, selectSmsType));
    }

    const columns = [
        {
            name: "ZONE",
            selector: (row) => row.zonename,
            sortable: true,
        },
        {
            name: "DIVISION",
            selector: (row) => row.divisionname,
            sortable: true,
        },
        {
            name: "REGION",
            selector: (row) => row.areaname,
            sortable: true,
        },
        {
            name: "TERRITORY",
            selector: (row) => row.territoryName,
            sortable: true,
        },
        {
            name: "NAME",
            selector: (row) => row.distribName,
            sortable: true,
        },
        {
            name: "ENROLL",
            selector: (row) => row.distribID,
            sortable: true,
        },
        {
            name: "DIS-POINT ID",
            selector: (row) => row.disPointID,
            sortable: true,
        },
        {
            name: "ADDRESS",
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: "CONTACT NUMBER",
            selector: (row) => row.contactNo,
            sortable: true,
        },

    ];

    const handleChange1 = (state) => {
        setSelectedData(state.selectedRows);

    };

    const smsSend = () => {
        console.log('selectedData', selectedData)
        if (smsText == null) {
            alert("Please Type Your Message");
        } else if (selectedData.length < 1) {

            alert("Please select At lease one Checkbox");
        } else {
            dispatch(sendSms(selectedData, smsText));
        }


    }

    const Demo = [
        { value: "1", label: "Value" },
        { value: "2", label: "Volume" },
    ];

    const sendType = [
        { value: "1", label: "Distributor" },
        { value: "2", label: "So" },
    ];

    const filteredItems = distibList.filter(
        item =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };
        return (
            <FilterComponent
              onFilter={e => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          );
        }, [filterText, resetPaginationToggle]);

    return (
        <div>

            {
                <>
                    {
                        !smsStatus &&
                        <>
                            <div className="card p-3 dashboardCart ">
                                <div className="form-group row">
                                    <div className="col-lg-2">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">SMS Type</label>
                                            <RHFInput
                                                className="formSelect pt-0 "
                                                as={<Select options={sendType} />}
                                                rules={{ required: false }}
                                                name="smsType"
                                                register={register}
                                                placeholder="Select Sms type"
                                                isClearable
                                                onChange={(option) => {
                                                    setSelectSmsType(option?.value)
                                                }}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">Select Zones</label>
                                            <RHFInput
                                                className="formSelect pt-0"
                                                as={<Select options={zoneDropdownData()} />}
                                                rules={{ required: false }}
                                                name="zoneData"
                                                register={register}
                                                // value={selectZone}
                                                value=""
                                                isClearable
                                                onChange={(option) => {
                                                    divisionList(option?.value);
                                                    setSelectZone(option?.value);
                                                    setValue("divisionData", "");
                                                    setValue("areaData", "");
                                                    setValue("territory", "");
                                                }}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">Select Division</label>
                                            <RHFInput
                                                className="formSelect pt-0"
                                                as={<Select options={divisionDropdownData()} />}
                                                rules={{ required: false }}
                                                name="divisionData"
                                                register={register}
                                                value=""
                                                isClearable
                                                // value={CertificateMasterInput.isActive}
                                                onChange={(option) => {
                                                    // setType(option.value)
                                                    getAreaData(option?.value);
                                                    setSelectDivision(option?.value);
                                                    setValue("areaData", "");
                                                    setValue("territory", "");
                                                }}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">Select Region</label>
                                            <RHFInput
                                                className="formSelect pt-0"
                                                as={<Select options={areaDropdownData()} />}
                                                rules={{ required: false }}
                                                name="areaData"
                                                register={register}
                                                value=""
                                                isClearable
                                                // value={CertificateMasterInput.isActive}
                                                onChange={(option) => {
                                                    // setType(option.value)
                                                    // dispatch(getDistributorList(option.value, salesType));
                                                    getTerritoryData(option?.value)
                                                    setSelectArea(option?.value);
                                                    setValue("territory", "");
                                                }}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">Select Territory</label>
                                            <RHFInput
                                                className="formSelect pt-0"
                                                as={<Select options={territoryDropdownData()} />}
                                                rules={{ required: false }}
                                                name="territory"
                                                register={register}
                                                value=""
                                                isClearable
                                                // value={CertificateMasterInput.isActive}
                                                onChange={(option) => {
                                                    setSelectTerritory(option?.value);
                                                }}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md mt-5 mb-5">
                                        <Button size="sm" onClick={() => showReport()} >Show</Button>
                                    </div>
                                </div>

                                <div className="card p-3 sms">
                                    <div className="form-group">
                                        <div className="col-lg-6 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">SMS Text</label>
                                                <Form.Control as="textarea" rows="8"
                                                    autocomplete="off"
                                                    type="textarea"
                                                    placeholder="Type Here"
                                                    name="remarks"
                                                    // value={itemDataInputForNonBikeCarEntry?.remarks}
                                                    onChange={(e) => {
                                                        setSmsText(e.target.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            <button type="button"
                                                className="btn btn-primary btn-sm text-center ml-4" onClick={() => smsSend()}>
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
                                {/* <DataTable
                                    columns={columns}
                                    data={distibList}
                                    // pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='450px'
                                    selectableRowsHighlight
                                    highlightOnHover
                                    selectableRows
                                    onSelectedRowsChange={handleChange1}
                                    actions
                                    subHeaderComponent={subHeaderComponent}
                                /> */}
                                 <DataTable
                                    title="Contact List"
                                    selectableRows
                                    onSelectedRowsChange={handleChange1}
                                    columns={columns}
                                    data={filteredItems}
                                    defaultSortField="name"
                                    striped

                                    //pagination
                                    subHeader
                                    subHeaderComponent={subHeaderComponent}
                                 />

                            </div>
                        </>

                    }

                    {
                        smsStatus
                        &&
                        <div>
                            <span className="spineercolor">{<LoadingSpinner />}</span>
                        </div>
                    }
                </>

            }
        </div>
    )
}

export default SmsSystem;