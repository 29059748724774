import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import DashboardDemo from "./DashboardDemo";


const DashboardChartContainer = () => {

  // Check localStorage to see if the card should be shown
  const [showCard, setShowCard] = useState(() => {
    const savedState = localStorage.getItem('showCard');
    return savedState === null ? true : JSON.parse(savedState);
  });

  const handleCloseCard = () => {
    setShowCard(false);
    localStorage.setItem('showCard', JSON.stringify(false)); // Update localStorage
  };

  useEffect(() => {
    if (showCard) {
      const timer = setTimeout(() => {
        setShowCard(false);
        localStorage.setItem('showCard', JSON.stringify(false)); // Update localStorage
      }, 5000); // Hide card after 5 seconds

      return () => clearTimeout(timer); // Cleanup on component unmount
    }
  }, [showCard]);

  // Inline styles for the sliding animation
  const notificationCardStyle = {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    margin: '1rem',
    animation: 'slideInUp 0.5s ease-out',
    zIndex: 1050, // Ensure the card is above other content
  };

  // CSS keyframes animation for sliding effect
  const keyframes = `
    @keyframes slideInUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

  // Styles for the card
  const cardStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const cardHeaderStyle = {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '0.75rem 1.25rem',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const cardBodyStyle = {
    padding: '1.25rem',
  };

  return (
    <div className="container-fluid">
      <DashboardDemo />
      {showCard && (
        <div style={notificationCardStyle}>
          <style>
            {keyframes}
          </style>
          <div style={cardStyle}>
            <div style={cardHeaderStyle}>
              <img
                src={toAbsoluteUrl("/media/logos/welcome.png")}
                alt="Akij Insaf Ltd"
                style={{ height: '40px', marginRight: '10px' }}
              />
              <span className="card-title" style={{ margin: 0 }}>Welcome to ABL Sales Software.</span>
              <button
                onClick={handleCloseCard}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#ffffff',
                  fontSize: '1.25rem',
                  cursor: 'pointer',
                }}
              >
                &times;
              </button>
            </div>
            {/* <div style={cardBodyStyle}>
                            <p className="card-text" style={{ margin: 0 }}>
                                This card will automatically close after 5 seconds.
                            </p>
                        </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default DashboardChartContainer;
