import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCertificateMasterList } from "../../../../certificates/certificate-master/_redux/actions/CertificateListAction";
import DistributorReportsFilterBr from "../../../information/components/DistributorReports/DistributorReportsFilterBr";
import DistributorReportBrDataTable from "./DistributorReportBrDataTable";


const DistributorReportsBrContainer = () => {
  const certificateMasterData = useSelector(
    (state) => state.CertificateListReducer.certificateMasterList
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = React.createRef();
  const modalAddStatus = useSelector(
    (state) => state.CertificateListReducer.addStatus
  );
  const [show, setShow] = useState(false);
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getCertificateMasterList());
  }, []);

  useEffect(() => {
    if (modalAddStatus) {
      setShow(false);
    }
  }, [modalAddStatus]);

  return (
   <>
              <DistributorReportsFilterBr />
              {/* <DistributorReportBr /> */}
              <DistributorReportBrDataTable />
           
      </> 
  );
};

export default DistributorReportsBrContainer;
