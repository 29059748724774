import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { geSalesPersonTA, getCumalativeReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { Form, Button, Col } from "react-bootstrap";

const PER_PAGE = 10;
const SalesPersonWiseTarget = () => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const componentRef = useRef();
  const isLoading = useSelector((state) => state.secondarySales.distributorwiseLoading);
  const demandlist = useSelector((state) => state.secondarySales.cumalative);
  const productReport = useSelector((state) => state.secondarySales.soWiseAchivementProduct);
  console.log('productReport', productReport)
  const soWiseAchivementProductdetails = useSelector((state) => state.secondarySales.soWiseAchivementProductdetails);
  console.log('soWiseAchivementProductdetails', soWiseAchivementProductdetails)
  const totalTarget = useSelector((state) => state.secondarySales.skuCumalative);
  const totalWDays = useSelector((state) => state.secondarySales.skuCumalative?.totalDays);
  console.log('totalWDays :>> ', totalWDays);
  console.log('totalTargetNew', totalTarget)

  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(soWiseAchivementProductdetails?.length / PER_PAGE);
  console.log('pageCount', pageCount)
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState("prodID");
  const [searchTerm, setSearchTerm] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };


  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    const validation = showValidation();
    if (validation) {
      dispatch(geSalesPersonTA(fromDate, tDate, idZone, idDivision, idArea, idTerritory));
    }
  }

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    let startY = 10;
    const fontSize = 7; // Set the font size

    // Add title
    doc.setFontSize(8);
    doc.text(`Sales Person Wise Target: ${startDate} To ${toDate} `, 70, startY);

    let cumulativeTarget = 0;

    // First table's data
    productReport.forEach((item, pageIndex) => { // Add pageIndex parameter
      if (pageIndex === 0) { // Apply border only for the first page
        const borderX = 15;
        const borderY = startY + 2;
        const borderWidth = 180;
        const borderHeight = 21;

        // Add logo
        const logoUrl = "/media/logos/abl_logo.png";
        const logoWidth = 13;
        const logoHeight = 13;
        const logoX = 10;
        const logoY = 0;
        doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Draw the border
        doc.rect(borderX, borderY, borderWidth, borderHeight);

        // Calculate cumulative target
        const cumulativeTarget = (
          parseInt(totalTarget.biscuitTargetPrice) +
          parseInt(totalTarget.cookisTargetPrice) +
          parseInt(totalTarget.cakeTargetPrice) +
          parseInt(totalTarget.waferTargetPrice) +
          parseInt(totalTarget.snacksTargetPrice)
        );

        // Add content within the border
        doc.setFontSize(fontSize);
        doc.text(`SO Point Name: ${item.soPointName}\nTerritory Name: ${item.territoryName}\nSo Name: ${item.soName}\nEnroll: ${item.soEnroll}\nDealer Name: ${item.disName}`, borderX + 5, borderY + 5);
        doc.text(`Biscuit: ${formatValueWithCommas(parseInt(totalTarget.biscuitTargetPrice))}\nCookies: ${formatValueWithCommas(parseInt(totalTarget.cookisTargetPrice))}\nCake: ${formatValueWithCommas(parseInt(totalTarget.cakeTargetPrice))}\nWafer: ${formatValueWithCommas(parseInt(totalTarget.waferTargetPrice))}\nSnacks: ${formatValueWithCommas(parseInt(totalTarget.snacksTargetPrice))}\nCumulative Target: ${formatValueWithCommas(cumulativeTarget)} `, borderX + 125, borderY + 5);
      } else {
        doc.setFontSize(fontSize);
        doc.text(`SO Point Name: ${item.soPointName}\nTerritory Name: ${item.territoryName}\nSo Name: ${item.soName}\nnEnroll: ${item.soEnroll}\nDealer Name: ${item.disName}`, 15, startY + 20);
        doc.text(`Biscuit: ${formatValueWithCommas(parseInt(totalTarget.biscuitTargetPrice))}\nCookies: ${formatValueWithCommas(parseInt(totalTarget.cookisTargetPrice))}\nCake: ${formatValueWithCommas(parseInt(totalTarget.cakeTargetPrice))}\nWafer: ${formatValueWithCommas(parseInt(totalTarget.waferTargetPrice))}\nSnacks: ${formatValueWithCommas(parseInt(totalTarget.snacksTargetPrice))}\nCumulative Target: ${formatValueWithCommas(cumulativeTarget)} `, 125, startY + 20);
      }

      startY += 14;

      // Second table's headers
      const tableHeaders = [
        "PRODUCT CODE",
        "CATEGORY NAME",
        "TP PRICE",
        "SKU NAME",
        "TARGET QUANTITY(CTN)",
        "TARGET VALUE",
        "PER DAY TARGET"
      ];

      doc.autoTable({
        head: [tableHeaders],
        body: sortedList.map((product) => [
          product.prodID,
          product.prodCatagories,
          (parseFloat(product.productTP).toFixed(2)),
          product.prodName,
          Math.round(product.targetQty),
          Math.round(product.targetPrice),
          (parseFloat(product.targetQty) / totalWDays).toFixed(2)
        ]),
        theme: 'grid',
        startY: startY + 10,
        styles: { 
          fontSize: 6, 
          cellPadding: 1, 
          valign: 'middle', 
          fontWeight: 'bold' 
        },
        
        margin: { top: startY + 10 }
      });

      startY += 15 + (PER_PAGE * 12) + 15;
    });

    doc.save(`Sales Person Wise Target: ${startDate} To ${toDate}.pdf`);
  };



  //For Sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
      setSearchTerm(""); // Reset search term
    }
  };

  // Sorting logic
  const sortedList = useMemo(() => {
    if (!Array.isArray(soWiseAchivementProductdetails)) {
      return [];
    }
  
    const filteredList = soWiseAchivementProductdetails.filter(
      (item) =>
        !searchTerm ||
        String(item.prodID).includes(searchTerm)
    );
  
    return filteredList.sort((a, b) => {
      const categoryA = a.prodCatagories.toUpperCase();
      const categoryB = b.prodCatagories.toUpperCase();
  
      if (sortOrder === "asc") {
        return categoryA.localeCompare(categoryB);
      } else {
        return categoryB.localeCompare(categoryA);
      }
    });
  }, [soWiseAchivementProductdetails, searchTerm, sortOrder, sortedColumn]);
  


  //Pagination
  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const formatValueWithCommas = (value) => {
    return value.toString().replace(/(\d)(?=(\d{2})+\d$)/g, "$1,");
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <span className="button-010">Sales Person Wise Target1</span>
            </div>
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>

          <div className="col-md-3 mt-5 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}>Show Report</Button>
          </div>
        </div>

        <div className="col mt-5 mb-5">
          {!isLoading && productReport?.length > 0 && (
            <button className="button-pdf ml-2" onClick={downloadAsPDF}>Download as PDF</button>
          )}
        </div>
        <div>
          {isLoading && <LoadingSpinner />}
          {!isLoading && productReport?.length === 0 && (
            <div className="text-center mt-5 mb-5">
              <h5 className="bg-warning p-2">No Data Found</h5>
            </div>
          )}
          {!isLoading && productReport?.length > 0 && (
            <div>
              <div className="card-custom card-stretch gutter-b">

                <div className="searchBox pl-5">
                  <form className="form float-right">
                    <button>
                      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </button>
                    <input
                      className="input"
                      placeholder="Search Product ID"
                      required=""
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </form>
                </div>

                <div className="card-body" >
                  <div className="container-fluid p-0">
                    <div className="table-responsive">
                      <div>

                        <table className="table table-head-custom table-vertical-center item-add-table borderTableims" id="table-pdf" ref={componentRef}>

                          <tbody>
                            {productReport
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    SO POINT NAME: {item.soPointName}<br></br>
                                    TERRITORY NAME: {item.territoryName}<br></br>
                                    SO NAME: {item.soName} <br></br>
                                    ENROLL: {item.soEnroll} <br></br>
                                    DEALER NAME: {item.disName} <br></br>
                                  </td>
                                  <td>
                                    BISCUIT: {formatValueWithCommas(parseInt(totalTarget.biscuitTargetPrice))} <br></br>
                                    COOKIES: {formatValueWithCommas(parseInt(totalTarget.cookisTargetPrice))} <br></br>
                                    CAKE: {formatValueWithCommas(parseInt(totalTarget.cakeTargetPrice))} <br></br>
                                    WAFER: {formatValueWithCommas(parseInt(totalTarget.waferTargetPrice))} <br></br>
                                    Snacks: {formatValueWithCommas(parseInt(totalTarget.snacksTargetPrice))} <br></br>
                                    CUMULATIVE TARGET: {" "}
                                    {formatValueWithCommas((
                                      parseInt(totalTarget.biscuitTargetPrice) +
                                      parseInt(totalTarget.cookisTargetPrice) +
                                      parseInt(totalTarget.cakeTargetPrice) +
                                      parseInt(totalTarget.waferTargetPrice) +
                                      parseInt(totalTarget.snacksTargetPrice)
                                    ))} <br></br>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        <table className="table table-head-custom table-vertical-center item-add-table borderTableims" >
                          <thead>
                            <tr>
                              <th scope="col" onClick={() => handleSort("prodID")}>
                                PRODUCT CODE{" "}
                                {sortedColumn === "prodID" && (
                                  <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                                )}
                              </th>
                              <th scope="col">CATEGORY NAME</th>
                              <th scope="col">TP PRICE</th>
                              <th scope="col">SKU NAME</th>
                              <th scope="col">TARGET QUANTITY(CTN)</th>
                              <th scope="col">TARGET VALUE</th>
                              <th scope="col">PER DAY TARGET</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedList
                              .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
                              .map((product, productIndex) => (
                                <tr key={productIndex}>
                                  <td>{product.prodID}</td>
                                  <td>{product.prodCatagories}</td>
                                  <td>{product.productTP}</td>
                                  <td>{product.prodName}</td>
                                  <td>{Math.round(product.targetQty)}</td>
                                  <td>{Math.round(product.targetPrice)}</td>
                                  <td>{(parseFloat(product.targetQty) / totalWDays).toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="pagination">
                        {currentPage > 1 && (
                          <>
                            <a href="#" onClick={(e) => handleClick(e, currentPage - 1)}>
                              &lt; {/* Left arrow */}
                            </a>
                            <a href="#" onClick={(e) => handleClick(e, 1)}>1</a>
                            {currentPage > 3 && <span>---</span>}
                          </>
                        )}

                        {Array.from({ length: pageCount }, (_, i) => {
                          if (
                            i + 1 === 1 ||
                            i + 1 === pageCount ||
                            (i + 1 >= currentPage - 1 && i + 1 <= currentPage + 1)
                          ) {
                            return (
                              <a
                                href="#"
                                key={i}
                                onClick={(e) => handleClick(e, i + 1)}
                                className={currentPage === i + 1 ? "active" : ""}
                              >
                                {i + 1}
                              </a>
                            );
                          }
                          return null;
                        })}

                        {currentPage < pageCount && (
                          <>
                            {currentPage < pageCount - 2 && <span>---</span>}
                            <a href="#" onClick={(e) => handleClick(e, pageCount)}>
                              {pageCount}
                            </a>
                            <a href="#" onClick={(e) => handleClick(e, currentPage + 1)}>
                              &gt; {/* Right arrow */}
                            </a>
                          </>
                        )}
                      </div>






                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesPersonWiseTarget;
