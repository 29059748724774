import axios from "axios";
import { GetEmployeeInformationWithoutAdmin } from "../../../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_SEARCH_DATA_INPUT, payload: formData });
};
export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const submitStatusRemove = () => (dispatch) => {
  dispatch({ type: Types.ZONE_SUBMIT_STATUS_REMOVE, payload: null });
};
export const productSubmitStausRemove = () => (dispatch) => {
  dispatch({ type: Types.PRODUCT_SUBMIT_STATUS_REMOVE, payload: null });
};
export const handleSubmit = (inputData) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/ZoneEntry?ZoneName=${inputData.zoneName}`;
    axios
      .post(postUrl)
      .then(function (response) {

        
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.messege);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }
  dispatch({
    type: Types.ZONE_SAVE,
    payload: responseList,
  });
};
export const getDistributorList = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&TerritoryId=${areaId}&DistribCat=${salesTypeId}`;
  console.log('dis url :>> ', url);
  await axios.get(url).then((res) => {
    console.log('dis res', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const GetDisCustomerbyUnit = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetDisCustomerbyUnit?unitId=98&TerritoryId=${areaId}&DistribCat=${salesTypeId}`;
  console.log('dis url :>> ', url);
  await axios.get(url).then((res) => {
    console.log('dis res', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const StockStatusUpdate = (DoId,distribId) => async (dispatch) => {
  let responseList = {
      isLoading: true,
      data: {},
      status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}StockStatusUpdate?DoId=${DoId}&distribId=${distribId}`;
  try {
      await axios.put(url).then((res) => {
          
          responseList.data = res.data.data == null ? [] : res.data.data;
          responseList.status = false;
          showToast("success", `${res.data.message}`);
      })
  } catch (error) {
      
  }

 
};
export const getCustomerListDetails  = async()  => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByCustomerID`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  return responseList;
 
};
export const getCorporateDistribution = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&AreaID=${areaId}&DistribCat=2`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const EditDateGet = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_SALES_LIST_EDIT_DATA, payload: item });
};
export const getCustomerBalance = (customerId) => async (dispatch) => {
  console.log('customerId', customerId);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};
export const GetNewCustomerBalanceByID = (customerId) => async (dispatch) => {
  console.log('customerId', customerId);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
 dispatch({ type: Types.GET_CUSTOMER_BALANCE_NEW, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetNewCustomerBalanceByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    console.log('balanceres', res)
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
    console.log('responseList', responseList)
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE_NEW, payload: responseList });
};

export const getCustomerSalesGroupInfo = async (customerId) =>  {
  console.log('customerId', customerId);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
 return responseList;
};

export const getProductList = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPrimaryItemPriceWithStock?unitId=98&DistribCat=${salesType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getSalesGroupProductList = (salesType,option) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  console.log('option', option);

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPrimaryItemPriceWithSalesChange?unitId=98&DistribCat=${salesType}&salesGroup=${option.value?.salesGroupId}`;

  await axios.get(url).then((res) => {

    console.log("res",res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};

export const getSalesGroupProductListWithSpecialPriceEntry = (salesType,option,distibId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  console.log('option', option);

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPrimaryItemPriceWithSalesChangeWithSpecialPrice?unitId=98&DistribCat=${salesType}&salesGroup=${option.value?.salesGroupId}&DistibID=${distibId}`;

  await axios.get(url).then((res) => {
console.log('url :>> ', url);
    console.log("res",res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST_ENTRY, payload: responseList });
};

export const GetExportSalesGroupProductListWithSpecialPriceEntry = (salesType,option,distibId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  console.log('option', option);

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetExportPrimaryItemPriceWithSalesChangeWithSpecialPrice?unitId=98&DistribCat=${salesType}&salesGroup=${option.value?.salesGroupId}&DistibID=${distibId}`;

  await axios.get(url).then((res) => {
console.log('url :>> ', url);
    console.log("res",res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST_ENTRY, payload: responseList });
};

export const getSalesGroupProductListWithSpecialPrice = (salesType,option,distibId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  console.log('option', option);

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPrimaryItemPriceWithSalesChangeWithSpecialPrice?unitId=98&DistribCat=${salesType}&salesGroup=${option.value?.salesGroupId}&DistibID=${distibId}`;

  await axios.get(url).then((res) => {
console.log('url :>> ', url);
    console.log("res",res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};

export const GetPrimarySalesDeliveryAllZoneEntry = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=1, disId=0, salesType,option) => async (dispatch) => {
  console.log('disoption :>> ', option);
  let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
  };

  dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_ENTRY_PAGE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZoneWithDeliveryForEntryPage?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}&DistibID=${disId}`;
  await axios.get(url).then((res) => {
     console.log('res :>> ', res);
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
  });
  dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_ENTRY_PAGE, payload: responseList });

  dispatch(getSalesGroupProductListWithSpecialPriceEntry(salesType, option, disId));
};


export const GetExportPrimarySalesDeliveryAllZoneEntry = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=1, disId=0, salesType,option) => async (dispatch) => {
  console.log('disoption :>> ', option);
  let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
  };

  dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_ENTRY_PAGE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZoneWithDeliveryForEntryPage?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}&DistibID=${disId}`;
  await axios.get(url).then((res) => {
     console.log('res :>> ', res);
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
  });
  dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_ENTRY_PAGE, payload: responseList });

  dispatch(GetExportSalesGroupProductListWithSpecialPriceEntry(salesType, option, disId));
};


export const getPromotionalProducctItemList = (itemtype) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  console.log('itemtype :>> ', itemtype);


  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPromotionalProductList?itemtype=${itemtype}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getGiftdemandList = (fromDate,toDate,customerID=0,selectgiftType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPromotionalDemandList?fromDate=${fromDate}&toDate=${toDate}&customerID=${customerID}&giftType=${selectgiftType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getEventList = (fromDate,toDate,customerID=0,selectgiftType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPromotionalDemandList?fromDate=${fromDate}&toDate=${toDate}&customerID=${customerID}&giftType=${selectgiftType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};

export const getPromotionProduct = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnitforPromotion?unitId=98&DistribCat=${salesType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getCorporateProductList = (selectZone,fDate, distribId, discate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPriceforCorporateSales?zoneId=${selectZone}&SaleDate=${fDate}&DistribID=${distribId}&DisCat=${discate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getSalesList = (areaid,territoryId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderReport?AreaID=${areaid}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const showApprovedReport = (fromDate, todate, areaId,territoryId, salesTypeId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_SALES_APPROVED_SALES_REPORT, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReport?FromDate=${fromDate}&ToDate=${todate}&AreaID=${areaId}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_SALES_REPORT, payload: responseList });
};
export const getCustomerProductDelivery = (areaid,territoryId, fromDate, toDate, salesTypeId) => async (dispatch) => {
  let responseList = {
    isPProductLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReport?AreaID=${areaid}&TerritoryId=${territoryId}&FromDate=${fromDate}&ToDate=${toDate}&DistribCat=${salesTypeId}`;


  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isPProductLoading = false
  });
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
};
export const getSalesListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReport?SalesOrderInvID=${orderId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_ORDER_DETAILS, payload: responseList });
};
export const getSalesApprovedDetails = (invoiceId) => async (dispatch) => {
  let responseList = {
    approvedLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReportDetails?InvoiceID=${invoiceId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.approvedLoading = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_DETAILS, payload: responseList });
};
export const getDistributorDeliveryDetails = (customerId, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
};
export const checkgetDistributorDeliveryDetails = async (customerId, fromDate, toDate) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });

};
export const getUpdateProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReportforUpdate?SalesOrderInvID=${orderId}`;
  await axios.get(url).then((res) => {
   
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const getUpdateSalesGroupProductList = (orderId,salesGroupId) => async (dispatch) => {
  console.log('salesGroupId action', salesGroupId);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesGroupOrderDetailsReportforUpdate?SalesOrderInvID=${orderId}&salesGroupId=${salesGroupId}`;
  await axios.get(url).then((res) => {
   
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const GetExportUpdateSalesGroupProductList = (orderId,salesGroupId) => async (dispatch) => {
  console.log('salesGroupId action', salesGroupId);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesGroupOrderDetailsReportforUpdateExport?SalesOrderInvID=${orderId}&salesGroupId=${salesGroupId}`;
  await axios.get(url).then((res) => {
   
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};
export const getCorporateUpdateProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReportforUpdateCorporateSales?SalesOrderInvID=${orderId}`;

  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const productSelecteditem = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_SELECTED, payload: item });
};
export const delteProductList = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_DELETE, payload: item });
};
export const productQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_QUANTITY, payload: formData });
};


export const productUpdateInputQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_UPDATE_QUANTITY, payload: formData });
};
export const salesSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,salesGroupId) => async (dispatch) => {
  console.log('submitDatacustomer', submitData)

  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      newList.push(productData);
    }
  }

  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&salesGroupId=${submitData?.disSalesGroupId}`;
    
    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
    
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  }


};


export const ExportSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,salesGroupId,pidate,PI,Conrate) => async (dispatch) => {
  console.log('submitDatacustomer', submitData)

  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      newList.push(productData);
    }
  }

  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertExportSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&salesGroupId=${submitData?.disSalesGroupId}&PiDate=${pidate}&PiNumber=${PI}&ConRate=${Conrate}`;
    
    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
    
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  }


};






export const TradmemarkSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,salesGroupId) => async (dispatch) => {

  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  console.log("productList",productList);
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      newList.push(productData);
    }
  }

  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertTradeOfferSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.damagE_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&salesGroupId=${submitData?.disSalesGroupId}`;

    
    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
    
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  }


};
export const giftSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,selectInvoice,selectsaleTypes,selectremarks) => async (dispatch) => {

  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertPromotionSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&demandID=${selectInvoice}&saleTypes=${selectsaleTypes}&Remarks=${selectremarks}`;
  console.log('event postUrl :>> ', postUrl);
    //return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
        console.log('event newList :>> ', newList);
        console.log('event response :>> ', response);
    
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  }


};
export const stockSubmit = (submitData, productList, selectZone, selectDivision, selectArea) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;

  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }


  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertDistribStok?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DistribID=${intCustomer}&SDate=2022-02-28`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }
  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};

export const corporateSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory, salesDate, distribaddress) => async (dispatch) => {
 
  let newList = [];
  let quantityStatus = true;

  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });



  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };

    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrderCorporateSales?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&salesGroupId=${submitData?.disSalesGroupId}&DistribAddress=${distribaddress}&SalesDate=${salesDate}`;



    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
      
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const sampleSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory, salesDate, distribaddress, isVat, remarks) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };


  console.log('submitData', submitData);
  console.log('productList', productList);







  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSampleSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&Remarks=${remarks}&VatImpact=${isVat}`;
   
    // let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}/api/RemoteOrder/InsertSampleSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const tradeOfferSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea, salesDate, distribaddress) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertPromotionalSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const salesUpdate = (invoiceId, productList) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
console.log('productList :>> ', productList);

  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }

  }
  if (newList.length == 0) {
    showToast("error", "Please type at least One quantity");
    return false;
  }

 




  // const { intCustomer, monCustBalance, coaid, intsalesofficeid } = submitData;
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderUpdate?SalesOrderInvID=${invoiceId}`;


    axios
      .put(postUrl, newList)
      .then(function (response) {

        //   responseList.data = response.data;

        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};


export const RemoteAppUpdate = (empenroll,role) => async (dispatch) => {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/UpdateEmployeeInformation?enroll=${empenroll}&role=${role}`;


    axios
      .put(postUrl)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
          dispatch(GetEmployeeInformationWithoutAdmin(empenroll));
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};


export const RemoteAppUpdateUserName = (empenroll,username) => async (dispatch) => {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/UpdateUserName?enroll=${empenroll}&username=${username}`;


    axios
      .put(postUrl)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
          dispatch(GetEmployeeInformationWithoutAdmin(empenroll));
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};


export const RemoteAppUpdatePassword = (empenroll,password) => async (dispatch) => {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/UpdatePassword?enroll=${empenroll}&password=${password}`;


    axios
      .put(postUrl)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
          dispatch(GetEmployeeInformationWithoutAdmin(empenroll));
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};






export const salesDelete = (id, areaId) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_SALES_TITEM, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDelete?SalesOrderInvID=${id}`)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SALES_TITEM, payload: false });
        // dispatch(getSalesList(areaId))
      }
    })
}
export const getDemandInvoiceListByCustomer = async (customerID) =>  {

try {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetInvoiceNumberBYCustomer?customerID=${customerID}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  return responseList;
} catch (error) {
  
}

}
export const cleanRemoteSalesData = (id) => (dispatch) => {
  dispatch({ type: Types.CLEAR_REMOTE_SALES_DATA, payload: null });
}

export const getDistributorListPermissionWise = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0, type = 0) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistributorListPermissionWise?`;
  console.log('dis-url', url)
  if (type !== null) {
    url += `&type=${type}`
  }
  if (zoneId !== null) {
    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&Territory=${territoryId}`
  }
  

  await axios.get(url).then((res) => {
    console.log('dis-res :>> ', res);
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTORLISTPERMISSION_FOR_SMS, payload: responseList });
};
export const sendSms = (selectedData,smsText) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  console.log('selectedData', selectedData);
  // return false;

  dispatch({ type: Types.SMS_SEND_FROM_IMS, payload: responseList });
  const newList = [];

  for (let i = 0; i < selectedData.length; i++) {
    let smsData = {
      userId: 0,
      contactNo:"",
      message: ""
    };

   
      smsData.userId = selectedData[i].distribID;
      smsData.contactNo = selectedData[i].contactNo;
      smsData.message = smsText;
      newList.push(smsData);
   
  }

  //let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/SmsServiceStoreForIms`;
  let url = `https://remotesales.akijinsaf.com/api/SecondarySales/SmsServiceStoreForIms`;
  


  await axios.post(url,newList).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    showToast('success', "Data send Successfully");

  });
  dispatch({ type: Types.SMS_SEND_FROM_IMS, payload: responseList });
};

export const clearList = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_LIST, payload: data });
};
export const GetDamageCustomerbyUnit = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetDamageCustomerbyUnit?unitId=98&TerritoryId=${areaId}&DistribCat=${salesTypeId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_CUSTOMER_BY_UNIT, payload: responseList });
};
export const getPrimaryDeliveryDistributorwithProductVerticlZone = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0,Fdate,Tdate, type = 0) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryDistributorwithProductVerticlZone?`;
  console.log('now-url', url)
 
  if (zoneId !== null) {
    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&Territory=${territoryId}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (type !== null) {
    url += `&Type=${type}`
  }
  

  await axios.get(url).then((res) => {
    console.log('dis-res :>> ', res);
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRIMARY_DELIVERY_DISTRIBUTOR_VERTICLE, payload: responseList });
};

export const SubdistributorEntry = (inputData) => (dispatch) => {
  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
  };

try {
    axios.post(`${process.env.REACT_APP_API_PUBLIC_URL}SuperDepoDistributorEntry?DisId=${inputData}`)
    .then((res) => {
    console.log('res', res)
     
        const { data, message } = res.data;
        responseList.rolesList = data.data;
        responseList.message = message;
        responseList.rolesListPaginated = data;
        responseList.isLoading = false;
        showToast('success', res.data.data);
 
    }).catch((err) => {
     
      responseList.isLoading = false;
      responseList.message = "Something went wrong!"
      showToast('error', responseList.message)
    })
} catch (error) {
}

};
