import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getProductList,
  productSelecteditem,
  itemAddInput,
  productQuantity,
  InputHandle,
  cleanRemoteSalesData,
  getSOListByArea,
  getSOPointByArea,
  salesSubmitBySoPoint,
  getLockSystemData,
  getItemforStockProduct,
  getSoInformation,
  getItemValidationforStockProduct
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getStatusList } from "../../_redux/actions/SecondarySales/SecondarySalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const SoSecondarySalesNew = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.secondary.itemDataInput);
  const productCost = useSelector((state) => state.secondary.productCost);
  const ProductStockSum = useSelector((state) => state.secondary.ProductStockSum);
  const fillterProductList = useSelector(
    (state) => state.secondary.filterProduct
  );
  const productList = useSelector((state) => state.secondary.productList);
  const submitLoading = useSelector((state) => state.secondary.submitLoading);
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const availableBalance = useSelector(
    (state) => state.secondary.availableBalance
  );
  const restBalance = useSelector(
    (state) => state.secondary.restBalance
  );

  const submitsecondaryStatus = useSelector((state) => state.secondary.submitsecondaryStatus);



  const creditLimit = useSelector((state) => state.secondary.creditLimit);
  const countProduct = useSelector((state) => state.secondary.productQuantity);
  const balanceLoader = useSelector((state) => state.secondary.balanceLoader);
  const submitData = useSelector((state) => state.secondary.submitData);
  const productSubmitStatus = useSelector(
    (state) => state.secondary.productSubmitStatus
  );




  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [soList, setSoList] = useState([]);
  const [sOPointid, setSoPointId] = useState(null);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectSo, setSelectSo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [distribType, setDistribType] = useState(null);
  let [distributionId, setdistributionId] = useState(null);
  const [soId, setSoId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [lockStatus, setLockStatus] = useState(null);
  const [memoData, setMemoData] = useState({
    orderValue: 0,
    totalmemoQty: 0,
    totalSkuQty: 0,
    visitedOutlet: 0
  });

  const [soInfo, setSoInfo] = useState({
    zoneName: null,
    divisionName: null,
    areaName: null,
    soName: null,
    soPointID: null,
    soid: null,
    distribName: null,
    soPointName: null
  })
  const [status, Setstatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState(5);

  useEffect(() => {
    if (submitsecondaryStatus === true) {
      setTimeout(() => { history.go(0) }, 3000);
    }

  }, [submitsecondaryStatus]);



  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);

    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    //dispatch(getProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());


  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {

    if (productSubmitStatus) {

      setTimeout(() => {
        setValue("intDistributorId", "");

        getSOListDate(selectArea, startDate);

        setDisabled(false);
      }, 3000);
      dispatch(getProductList());
      // dispatch(cleanRemoteSalesData());
    }
  }, [productSubmitStatus]);

  const initialSOData = async () => {
    // setDisabled(false);
    let soData = await getSOListByArea(selectArea, startDate);
    let soDetai = soData.data.slice();
    setSoList(soDetai);
  }

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();

  }, []);

  // SO Secondary Sales Entry
  const initialData = async () => {
    let lockData = await getLockSystemData();
    let soDetails = await getSoInformation();
    console.log('soDetails :>> ', soDetails);
    let statusData = await getStatusList();
    Setstatus(statusData.data);

    if (soDetails.data != null) {
      const { zoneName, divisionName, areaName, soName, soPointID, soid, distribName, soPointName, territoryName } = soDetails.data;
      const soInfomation = { ...soInfo };
      soInfomation.zoneName = zoneName;
      soInfomation.divisionName = divisionName;
      soInfomation.areaName = areaName;
      soInfomation.territoryName = territoryName;
      soInfomation.soName = soName;
      soInfomation.soPointID = soPointID;
      soInfomation.soid = soid;
      soInfomation.distribName = distribName;
      soInfomation.soPointName = soPointName;

      setSoInfo(soInfomation);
    }
    setLockStatus(lockData.data[0]);

  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }


  const changeProductQuantity = (name, value) => {
    dispatch(getItemValidationforStockProduct(soInfo.soPointID, startDate, name));
    dispatch(productQuantity(name, value));
  };



  const getSOListDate = async (value, date) => {
    // let solIst = await getSOListByArea(value,date);
    let solIst = await getSOPointByArea(value, date);
    setSoList(solIst.data);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (memoData.visitedOutlet > 999) {
      alert("Visited Outlet must less than 1000");
      return false;
    }
    else if (memoData.visitedOutlet == 0) {
      alert("Please Input T.Visited Outlet");
      return false;
    } else if (memoData.orderValue == 0) {
      alert("Please Input T.Order value ");
      return false;
    }
    else if (memoData.totalmemoQty == 0) {
      alert("Please Input T.Memo Qty");
      return false;
    }
    else if (memoData.totalmemoQty > 999) {
      alert("T.Memo Qty must less than 1000 ");
      return false;
    } else if (memoData.totalSkuQty == 0) {
      alert("Please Input Number of T.Memo Line ");
      return false;
    }
    else if (memoData.totalSkuQty > 999) {
      alert("T.Memo Line must less than 1000");
      return false;
    }
    else {
      setDisabled(true);
      dispatch(salesSubmitBySoPoint(soInfo.soPointID, memoData, productList, startDate, selectStatus));
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    dispatch(getItemforStockProduct(soInfo.soPointID, input.value, user.employeeType));
    console.log('user', user)
    dispatch(getProductList(user.employeeType));

  };

  const handleMemoData = (value, name) => {

    const cloneObj = { ...memoData };
    cloneObj[name] = value;
    setMemoData(cloneObj);
  }


  const statusDropdownData = () => {
    let options = [];
    if (status?.length > 0) {
      status.forEach((item) => {
        let itemData = {
          value: item.stausId,
          label: item.statusName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState('itemName');

  //For Sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
      setSearchTerm("");
    }
  };

  // Sorting logic
  const sortedList = useMemo(() => {
    if (!Array.isArray(productList)) {
      return [];
    }

    const filteredList = productList.filter((item) => {
      const itemNameLower = String(item.itemName).toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();
      return !searchTermLower || itemNameLower.includes(searchTermLower);
    });

    const sorted = filteredList.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
      } else {
        return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
      }
    });

    return sorted;
  }, [productList, searchTerm, sortOrder, sortedColumn]);

  const valueStyle = {
    color: 'yellow',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
  };




  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={onSubmit}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2 titleposition">
                {/* <span className="button-010">SO Secondary Sales Entry</span> */}
                <h3 className="mb-0 pb-0">SO Secondary Sales Entry</h3>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="form-group row">


            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Name</p>
                  <h6>{soInfo.soName + '[' + soInfo.soPointName + ']'}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Zone</p>
                  <h6>{soInfo.zoneName}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Division</p>
                  <h6>{soInfo.divisionName}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Region</p>
                  <h6>{soInfo.areaName}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Territory</p>
                  <h6>{soInfo.territoryName}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>

            <div className="col-lg-4">
              <Form.Group as={Col} controlId="formGridState">
                <div className="top_card_so">
                  <p className="Secondary_so_information text-primary">Distributor</p>
                  <h6>{soInfo.distribName}</h6>
                </div>
              </Form.Group>
              {/* <hr className="text-danger"></hr> */}
            </div>

            {/* <div className="col-lg-1">
              <Form.Group as={Col} controlId="formGridState">
              <p className="Secondary_so_information text-primary">SoPoint : </p>
              <h6>{soInfo.soPointName}</h6>
              </Form.Group>
              <hr className="text-danger"></hr>
            </div> */}

          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                {
                  lockStatus?.status == "Y" &&
                  <Form.Control

                    min={lockStatus?.lockTime}
                    max={lockStatus?.maxLock}
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    className="fromStyle"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                }
                {
                  lockStatus?.status == "N" &&
                  <Form.Control
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    className="fromStyle"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                }
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Visited Outlet</label>

                <Form.Control
                  type="number"
                  placeholder="Enter Visited Outlet"
                  name="visitedOutlet"
                  className="fromStyle"
                  onChange={(e) => handleMemoData(e.target.value, 'visitedOutlet')}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Order value</label>

                <Form.Control
                  type="number"
                  placeholder="Enter Order value"
                  name="orderValue"
                  className="fromStyle"
                  onChange={(e) => handleMemoData(e.target.value, 'orderValue')}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Memo Qty</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Memo Qty"
                  name="totalmemoQty"
                  className="fromStyle"
                  onChange={(e) => handleMemoData(e.target.value, 'totalmemoQty')}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Number of T.Memo Line</label>

                <Form.Control
                  type="number"
                  placeholder="Enter sku Qty"
                  name="totalSkuQty"
                  className="fromStyle"
                  onChange={(e) => handleMemoData(e.target.value, 'totalSkuQty')}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Delivery Status</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={statusDropdownData()} />}
                  rules={{ required: false }}
                  name="statusData"
                  register={register}
                  value=""
                  defaultValue={{ label: "Delivered", value: 5 }}
                  onChange={(option) => {
                    setSelectStatus(option.value)
                  }}

                  setValue={setValue}
                />
              </Form.Group>
            </div>


          </div>
          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <span className="paid__widget_New one text-center text-bold secondary-entry-label">Total Quantity(Pieces)</span>
                    <div className="achiveLayer">
                      <span style={containerStyle}>
                        
                        <span style={valueStyle}>{numberFormatDecemal(countProduct)}</span>
                      </span>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <span className="paid__widget_New two text-center text-bold secondary-entry-label">Total Stock Value</span>
                    <div className="achiveLayer">
                      <span style={containerStyle}>
                      <span style={valueStyle}>{numberFormatDecemal((ProductStockSum.toFixed(2)))}</span>
                        
                      </span>
                     
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {sortedList.length > 0 ? (
            <div className="searchBox" >
              <form className="form mt-2" style={{ backgroundColor: 'skyblue', float: 'right' }}>
                <span>
                  <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                    <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </span>
                <input
                  className="input"
                  placeholder="Search Item Name"
                  required=""
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </form>

            </div>
          ) : null}


          {/* {productList[0]?.netStock > 0 ? ( */}
          <div className="react-bootstrap-table table-responsive mt-2">
            <table className="table table table-head-custom table-vertical-center">
              {
                sortedList.length > 0 &&
                <thead>
                <th>SL</th>
                <th onClick={() => handleSort("itemID")} >Item ID
                  {sortedColumn === "itemID" && (
                    <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                  )}
                </th>
                <th>Item Name</th>
                <th>Stock</th>
                <th>Damage</th>
                <th>Net Stock</th>
                <th>Price</th>
                <th>Quantity</th>
              </thead>
              }
              
              
              <tbody>
                {sortedList.map((item, index) => (
                  item.prodStock > 0 && (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{parseInt(item.prodStock)}</td>
                      <td>{isNaN(parseInt(item.prodDamage)) ? 0 : parseInt(item.prodDamage)}</td>
                      <td className={item.netStock < 0 ? 'redText' : ''}>{isNaN(parseInt(item.netStock)) ? 0 : parseInt(item.netStock)}</td>
                      <td>{parseFloat(item.prodTrdpricebypic).toFixed(2)}</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={`${item.itemQuentityPic}`}
                            onChange={(e) => {
                              //dispatch(getItemValidationforStockProduct(sOPointid, startDate, item))
                              changeProductQuantity(item, e.target.value)
                            }
                            }
                          />
                        </Form.Group>
                        <span><small className="validation-symbol"> {item.status == false ? "Sorry !! Stock is not avaiable" : ""} </small></span>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  )
                ))}
              </tbody>
            </table>
            <div className="col-lg-12 mt-5">
              {/* {
                  submitLoading?
                  <button
                 
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit .....
                </button>:  */}
              {/* <button
                disabled={submitsecondaryStatus}
                  type="submit"
                  // disabled={disabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button> */}
                
                {
                sortedList.length > 0 &&
              <div className="col-lg-12 mt-5">
                <div className=" mb-5">
                  <div className="col-lg-12">
                    <button
                      disabled={disabled}
                      type="submit"

                      className="btn btn-success btn-block"
                    >
                      Submit
                    </button>
                  </div>

                </div>
              </div>
}
              {/* } */}

            </div>
          </div>
          {/* ) : null} */}
        </div>
      </form>
    </>
  );
};

export default SoSecondarySalesNew;
