import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import {
  getDistributorBrReport,
  getZoneListEntry,
  getTerritoryEntry,
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { useHistory } from "react-router-dom";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import * as XLSX from "xlsx/xlsx.mjs";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";


const DistributorReportsFilterBr = () => {

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [area, setArea] = useState([]);
  const dispatch = useDispatch();

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [distribType, setDistribType] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const { register, setValue } = useForm();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";


    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneListEntry();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {
    let options = [];
    if (zone) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const handleChangeTextInput = (inputName, inputValue) => {
    setStartDate(inputValue);
  };

  const handleListAction = (selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType) => {

    dispatch(getDistributorBrReport(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType));
  }

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }


  const showBrReport = () => {
    const validation = showValidation();
    if (validation) {
      handleListAction(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType);

    }
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const hanleExcelClick = async () => {
    let excelClick = await getDistributorBrReport(
      startDate,
      toDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory,
      3
    );
    console.log('excelClick', excelClick);
    const current = currentdate();

    let Heading = [['Distributor ID', 'Distributor Name', 'Opening Balance', 'Bank Receive',
      'Delivery Amount', 'Net Balance'
    ]];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    XLSX.writeFile(wb, 'Distributor Bank Receive Monthly' + " " + current + '.xlsx');
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5  card-top-border p-3 ">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <span className="button-010">Distributor Bank Receive Monthly</span>
                {/* <h3 className="mb-0 pb-0">Distributor Bank Receive Monthly</h3> */}
              </div>
            </div>
            <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            />
          </div>
          <div className="float-right ">
            <DownloadDropdown excelname='Distributor Bank Receive Monthly' />
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3">
            {/* <label className="form-label">Laycan From Date</label> */}
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2 mt-0 ml-4">
            <label className="form-label">Select Territory</label>
            <Select
              options={territoryDropdownData()}
              value={defultTerritory}
              onChange={(option) => {
                setSelectTerritory(option?.value);
                setdefultTerritory(option)
              }}
              defaultValue={defultTerritory}
            />
          </div>

          <div className="col-lg-3">
            <button className="button-621 mt-6" onClick={() => showBrReport()} >  Show Report</button>
            {/* <Button
              variant="primary"
              onClick={() => showBrReport()}
              size="sm"
              className="mt-6"
            >
              Show Report
            </Button> */}
            {/* <div className="float-right mt-3" onClick={() => history.go(0)}>
            {/* <div className="float-right mt-6" onClick={() => history.go(0)}>
              <i className="fas fa-sync-alt editIcon refreshIcon ml-4"></i>
              <p>Refresh</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DistributorReportsFilterBr;