import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    EditDateGet,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { InputHandle } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    getAreaListByDivisionEntry,
    getDivisionListByZoneEntry,
    getTerritoryEntry,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
    cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import {
    currentdate
} from "../../../../../domains/CCO/utils/DateHelper";
import {
    getSecondaryStockForExcel,
    getSecondaryStockForShowModified,
} from "../../_redux/actions/Delivery/DeliveryAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaSearch, FaDownload } from 'react-icons/fa';

const SecondarySalesStockModified = () => {
    const history = useHistory();

    const productReport = useSelector((state) => state.delivery.stockReportModified);
    const isLoading = useSelector((state) => state.delivery.stockModifiedLoading);
    const totals = useSelector((state) => state.delivery.totals);
    console.log('isLoading :>> ', isLoading);
    console.log('productReport :>> ', productReport);


    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectReportType, setSelectReportType] = useState(2);

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});



    const [reportType, setReportType] = useState([
        {
            id: 1,
            name: "Distributor",
        },
        {
            id: 2,
            name: "Point Wise",
        },
    ]);
    const componentRef = useRef();

    // const dispatch = useDispatch();
    // const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    let pageLimit = 5;

    const dispatch = useDispatch();

    // items list with pagination
    useEffect(() => {
        // dispatch(getSalesList());
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };
    const reportTypeDropdownData = () => {
        let options = [];
        if (reportType?.length > 0) {
            reportType.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.name,
                };

                options.push(itemData);
            });
        }

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);

    const itemDetail = (item) => {
        setItemifo(item);
        setItemID(item.orderId);
        setItemDetailShow(true);
    };
    let ref = React.createRef();
    const inputField = React.useRef(null);

    const printFilter = (filter) => {
        inputField.current.value = filter;
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };
    const gotoEditPage = (item) => {
        dispatch(EditDateGet(item));
        history.push(
            `/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`
        );
    };

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleListAction = (
        fromDate,
        idZone,
        idDivision,
        idArea,
        territoryId
    ) => {

        dispatch(
            getSecondaryStockForShowModified(
                fromDate,
                idZone,
                idDivision,
                idArea,
                territoryId,
                3
            )
        );
    };

    const [selectGeoType, setSelectGeoType] = useState(2);

    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const hanleExcelClick = async () => {
        setLoading(true);
        setError(null);

        try {
            let excelClick = await getSecondaryStockForExcel(
                startDate,
                selectZone,
                selectDivision,
                selectArea,
                selectTerritory,
                3
            );

            const current = currentdate();

            let Heading = [[
                'ZoneId',
                'ZoneName',
                'Divisionid',
                'DivisionName',
                'RegionID',
                'RegionName',
                'TerritoryId',
                'TerritoryName',
                'DisPointID',
                'DisPointName',
                'ProductID',
                'ProductName',
                'CategoryID',
                'CategoryName',
                'GroupName',
                'BrandName',
                'PackName',
                'Uom',
                'Price',
                'OpeningQty',
                'OpeningCtnQty',
                'OpeningValue',
                'LiftingQty',
                'LiftingCtnQty',
                'LiftingValue',
                'IMSPcsQty',
                'IMSCtnQty',
                'IMSValue',
                'ClosingQty',
                'ClosingCtnQty',
                'ClosingValue',
                'DamagePcsQty',
                'DamageCTNQty',
                'DamageValue',
                'NetStockQty',
                'NetStockCtn',
                'NetStockValue',
            ]];

            //Had to create a new workbook and then add the header
            const wb = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(worksheet, Heading);

            //Starting in the second row to avoid overriding and skipping headers
            XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
            const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
            //XLSX.writeFile(wb, 'ProductStock' + " " + current + '.xlsx');
            XLSX.writeFile(wb, 'Product Stock Report' + " - " + startDate + " - " + "Download Date" + " - " + current + '.xlsx');
        } catch (error) {
            console.error("Error:", error);
            setError("Network Error");

        } finally {
            setLoading(false);
        }
    };

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-9">
                        <div className="mt-2 p-2">
                            <span className="mb-0 pb-0 pageheadingText">Product Stock(Secondary)</span>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right">
                            <Button
                                size="sm"
                                onClick={hanleExcelClick}
                                disabled={loading}
                                className={loading ? 'bg-danger' : ''}
                            >
                                <FaDownload /> {loading ? 'Loading...' : 'Download'}
                            </Button>
                            {error && <div>Error: {error}</div>}
                            <DownloadDropdown excelname='Product Stock(Secondary)' />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option);
                                    setdefultDivision(null);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option);
                                    setdefultTerritory(null);
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>


                    <div className="col-lg-3">
                        <label className="form-label ml-4">Select Territory</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={territoryDropdownData()}
                                value={defultTerritory}
                                onChange={(option) => {
                                    //getTerritoryData(option?.value);
                                    setSelectTerritory(option?.value);
                                    setdefultTerritory(option)
                                }}
                                defaultValue={defultTerritory}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Report Type</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={reportTypeDropdownData()} />}
                                rules={{ required: false }}
                                name="reportType"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectReportType(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-5 mb-5 ">
                        <Button size="sm" onClick={() =>
                            handleListAction(
                                startDate,
                                selectZone,
                                selectDivision,
                                selectArea,
                                selectTerritory
                            )} > <FaSearch /> Show Report</Button>

                    </div>

                </div>
                <div>

                    <div>
                        {productReport?.length > 0 && (
                            <div className="container-fluid mb-3 mt-3 containerFixed">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget four">
                                            <div className="widget__left">
                                                <p className="balanceleft">Opening Qty Pcs</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalProductOpeningPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductOpeningPCQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget three">
                                            <div className="widget__left">
                                                <p className="balanceleft">Lifting Pcs Qty</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductLiftingPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductLiftingPCQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget two">
                                            <div className="widget__left">
                                                <p className="balanceleft">Ims PCS Qty</p>
                                            </div>

                                            <div>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductIMS_PCQty)}
                                                </h4>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget one">
                                            <div className="widget__left">
                                                <p className="balanceleft">Closing PCS Qty</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductClosingPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div className="overlay-layer bg-transparent"></div>
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductClosingCTQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}



                    </div>




                    <div className="row" id="id" ref={inputField}>

                        <div className="react-bootstrap-table table-responsive border-0 pl-5">
                            {isLoading ? (
                                <LoadingSpinner text="Loading Items List..." />
                            ) : productReport?.length > 0 ? (
                                <div
                                    className="stickyTable"
                                    style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
                                >
                                    <table className="table table-head-custom table-vertical-center item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                                        <thead>
                                            <tr>
                                                <th scope="col">ZONE</th>
                                                <th scope="col">DIVISION</th>
                                                <th scope="col">REGION</th>
                                                <th scope="col">TERRITORY</th>
                                                <th scope="col">DISTRIBUTOR POINT</th>
                                                <th scope="col">DISTRIBUTOR POINT ID</th>
                                                <th scope="col">PRODUCT ID</th>
                                                <th scope="col">PRODUCT NAME</th>
                                                <th scope="col">PRODUCT CATEGORY</th>
                                                <th scope="col">GROUP NAME</th>
                                                <th scope="col">BRAND NAME</th>
                                                <th scope="col">PACK NAME</th>
                                                <th scope="col">PRODUCT UOM</th>
                                                <th scope="col">OPENING(PCS)</th>
                                                <th scope="col">OPENING(CTN)</th>
                                                <th scope="col">OPENING(VALUE)</th>
                                                <th scope="col">LIFING (PCS)</th>
                                                <th scope="col">LIFING (CTN)</th>
                                                <th scope="col">LIFING (VALUE)</th>
                                                <th scope="col">IMS (PCS)</th>
                                                <th scope="col">IMS (CTN)</th>
                                                <th scope="col">IMS (VALUE)</th>
                                                <th scope="col">CLOSING (PCS)</th>
                                                <th scope="col">CLOSING(CTN)</th>
                                                <th scope="col">CLOSING(VALUE)</th>

                                                <th scope="col">DAMAGE(PCS)</th>
                                                <th scope="col">DAMAGE(CTN)</th>
                                                <th scope="col">DAMAGE(VALUE)</th>

                                                <th scope="col">Net Stock(PCS)</th>
                                                <th scope="col">Net Stock(CTN)</th>
                                                <th scope="col">Net Stock(VALUE)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productReport?.length > 0 &&
                                                productReport?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="alert bg-secondary">
                                                            <td>{item?.zoneName}</td>
                                                            <td>{item?.divisionName}</td>
                                                            <td>{item?.regionName}</td>
                                                            <td>{item?.territoryName}</td>
                                                            <td>{item?.disPointID}</td>
                                                            <td>{item?.disPointName}</td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td>{Math.round(item?.distributorSums.productOpeningPCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productOpeningCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productOpeningPrice)}</td>

                                                            <td>{Math.round(item?.distributorSums.productLiftingPCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productLiftingCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productLiftingPrice)}</td>

                                                            <td>{Math.round(item?.distributorSums.productIMS_PCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productIMS_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productIMSPrice)}</td>

                                                            <td>{Math.round(item?.distributorSums.productClosingPCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productClosingCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productClosingPrice)}</td>

                                                            <td>{Math.round(item?.distributorSums.productDMG_PCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productDMG_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productDMGPrice)}</td>

                                                            <td>{Math.round(item?.distributorSums.productNet_PCQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productNet_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productNetPrice)}</td>


                                                        </tr>
                                                        {item?.Details?.length > 0 &&
                                                            item?.Details?.map((detail, subindex) => (
                                                                <tr key={subindex}>
                                                                    <td>{detail?.zoneName}</td>
                                                                    <td>{detail?.divisionName}</td>
                                                                    <td>{detail?.regionName}</td>
                                                                    <td>{detail?.territoryName}</td>
                                                                    <td>{detail?.disPointID}</td>
                                                                    <td>{detail?.disPointName}</td>
                                                                    <td>{detail?.productID}</td>
                                                                    <td>{detail?.productName}</td>
                                                                    <td>{detail?.prodCatName}</td>
                                                                    <td>{detail?.prodGroup}</td>
                                                                    <td>{detail?.brandName}</td>
                                                                    <td>{detail?.packTypeName}</td>
                                                                    <td>{detail?.productUom}</td>
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningPCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningPrice)}
                                                                    </td>

                                                                    <td>
                                                                        {Math.round(detail?.productLiftingPCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productLiftingCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productLiftingPrice)}
                                                                    </td>



                                                                    <td>
                                                                        {Math.round(detail?.productIMS_PCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productIMS_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productIMSPrice)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productClosingPCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productClosingCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productClosingPrice)}
                                                                    </td>

                                                                    <td>
                                                                        {Math.round(detail?.productDMG_PCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productDMG_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productDMGPrice)}
                                                                    </td>

                                                                    <td>
                                                                        {Math.round(detail?.productNet_PCQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productNet_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productNetPrice)}
                                                                    </td>



                                                                </tr>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                        </tbody>
                                    </table>


                                </div>
                            ) : (
                                <div className="text-center p-10">No data available</div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SecondarySalesStockModified;
