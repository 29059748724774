import React, { useState } from 'react';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const styles = {
    calendarCard: {
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '380px',
      overflow: 'hidden',
      marginTop: '30px',
      marginLeft: '15px'
    },
    calendarHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#f9f9f9',
    },
    calendarHeaderTitle: {
      margin: '0',
      fontSize: '18px',
      flex: 1,
      textAlign: 'left',
    },
    calendarHeaderButtonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    calendarHeaderButton: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      fontSize: '18px',
      marginLeft: '5px',
      transition: 'background-color 0.3s',
    },
    calendarHeaderButtonHover: {
      backgroundColor: '#0056b3',
    },
    calendarBody: {
      padding: '10px',
    },
    calendarDays: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '5px',
      fontWeight: 'bold',
    },
    day: {
      textAlign: 'center',
    },
    calendarDates: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '5px',
    },
    date: {
      textAlign: 'center',
      padding: '10px',
      cursor: 'pointer',
      borderRadius: '4px',
    },
    dateActive: {
      backgroundColor: '#007bff',
      color: 'white',
    },
    dateHover: {
      backgroundColor: '#f0f0f0',
    },
    monthsRow: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: '5px',
      padding: '10px',
    },
    monthName: {
      textAlign: 'center',
      fontSize: '12px',
      color: '#000', // Default color
    },
    monthNameActive: {
      color: 'orange', // Active month color
    },
    monthCircleRow: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: '5px',
      padding: '0 10px 10px 10px',
    },
    monthCircle: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#ddd',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'transparent',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
      transition: 'background-color 0.3s',
    },
    monthCircleActive: {
      backgroundColor: 'orange',
      color: 'white',
    },
    monthCircleInactive: {
      backgroundColor: '#ddd',
    },
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const days = [];

    // Fill in empty cells before the first day
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(<div key={`empty-${i}`} style={styles.day} className="empty"></div>);
    }

    // Fill in the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(
        <div
          key={day}
          style={{
            ...styles.date,
            ...(day === new Date().getDate() && month === new Date().getMonth() && year === new Date().getFullYear() ? styles.dateActive : {}),
          }}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const changeMonth = (offset) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + offset, 1);
      return newDate;
    });
  };

  const handleMonthClick = (index) => {
    setCurrentDate(new Date(currentDate.getFullYear(), index, 1));
  };

  return (
    <div>
      <div style={styles.calendarCard}>
        <div style={styles.calendarHeader}>
          <h2 style={styles.calendarHeaderTitle}>
            {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
          </h2>
          <div style={styles.calendarHeaderButtonContainer}>
            <button
              onClick={() => changeMonth(-1)}
              style={styles.calendarHeaderButton}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.calendarHeaderButtonHover.backgroundColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.calendarHeaderButton.backgroundColor}
            >
              &lt;
            </button>
            <button
              onClick={() => changeMonth(1)}
              style={styles.calendarHeaderButton}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.calendarHeaderButtonHover.backgroundColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.calendarHeaderButton.backgroundColor}
            >
              &gt;
            </button>
          </div>
        </div>
        <div style={styles.calendarBody}>
          <div style={styles.calendarDays}>
            <div style={styles.day}>Mo</div>
            <div style={styles.day}>Tu</div>
            <div style={styles.day}>We</div>
            <div style={styles.day}>Th</div>
            <div style={styles.day}>Fr</div>
            <div style={styles.day}>Sa</div>
            <div style={styles.day}>Su</div>
          </div>
          <div style={styles.calendarDates}>
            {renderCalendar()}
          </div>
        </div>

        <div style={styles.monthsRow}>
          {months.map((month, index) => (
            <div
              key={index}
              style={{
                ...styles.monthName,
                ...(index === currentDate.getMonth() ? styles.monthNameActive : {}),
              }}
            >
              {month}
            </div>
          ))}
        </div>
        <div style={styles.monthCircleRow}>
          {months.map((month, index) => (
            <div
              key={index}
              style={{
                ...styles.monthCircle,
                ...(index === currentDate.getMonth() ? styles.monthCircleActive : styles.monthCircleInactive),
              }}
              onClick={() => handleMonthClick(index)}
            >
              {index === currentDate.getMonth() ? '' : ''}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
