import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
    EditDateGet,
    salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
    InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
    getAreaListByDivision,
    getAreaListByDivisionEntry,
    getDivisionListByZone,
    getDivisionListByZoneEntry,
    getTerritory,
    getTerritoryEntry,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getStrikeRateOfSecondary } from "../../_redux/actions/SecondarySales/SecondarySalesAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const StrikeRateOfSecondary = () => {
    const history = useHistory();
    const isLoading = useSelector((state) => state.secondarySales.strikeLoading);
    const demandlist = useSelector((state) => state.secondarySales.strikedata);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(0);
    const [area, setArea] = useState([]);

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const componentRef = useRef();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    const dispatch = useDispatch();

    // items list with pagination
    useEffect(() => {
        // dispatch(getSalesList());
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }


    // delete issuing authority list
    const confirmDelete = (id) => {
        dispatch(salesDelete(id, selectArea));
        // setTimeout(() => {history.go(0)}, 3000);
        // history.go(0);
    };

    const handleDeleteItem = (id) => {
        confirmAlert({
            title: "Confirm To Delete",
            message: `Are you sure to delete..?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => confirmDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const zoneDropdownData = () => {

        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);
    const [paginateData, setPaginateData] = useState([]);

    const itemDetail = (item) => {
        setItemifo(item);
        setItemID(item.orderId);
        setItemDetailShow(true);
    };
    let ref = React.createRef();
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = (input) => {
        setToDate(input);
    };

    const handleDateFilter = (option) => {
        handleChangeTodate(option);
    }

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const showValidation = () => {
        if (startDate == null || startDate == "") {
            alert("Please Select From Date");
            return false;
        }
        else if (toDate == null || toDate == "") {
            alert("Please select To Date");
            return false;
        }
        else {
            return true;
        }
    }

    const showReport = () => {
        const validation = showValidation();
        if (validation) {
            dispatch(getStrikeRateOfSecondary(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType));
        }
    }

    const getSalesValueType = (name, value) => {
        setSalesType(value);
        setSelectGeoType(value);
    }


    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                            <span className="button-010">LPC. ST Rate. Non EX % Report</span>
                            {/* <h3 className="mb-0 pb-0">LPC. ST Rate. Non EX % Report</h3> */}
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right ">
                            <DownloadDropdown excelname='LPC. ST Rate. Non EX % Report' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 ml-4">
                            <SimpleSgeoType
                                getSalesValue={getSalesValueType}
                                sGeoType={selectGeoType}
                            />
                        </div>
                    </div>
                </div>

                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={(event) => {
                                    handleDateFilter(event.target.value)
                                }}

                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option);
                                    setdefultDivision(null);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option);
                                    setdefultTerritory(null);
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-2 mt-0 ml-4">
                        <label className="form-label">Select Territory</label>
                        <Select
                            options={territoryDropdownData()}
                            value={defultTerritory}
                            onChange={(option) => {
                                //getTerritoryData(option?.value);
                                setSelectTerritory(option?.value);
                                setdefultTerritory(option)
                            }}
                            defaultValue={defultTerritory}
                        />
                    </div>
                    <div className="col mt-5">
                        <button className="button-621" onClick={() => showReport()} >  Show Report</button>
                        {/* <Button size="sm" onClick={() => showReport()}>Show Report</Button> */}
                    </div>
                </div>
                <div>
                </div>

                <div className="row mt-5" id="id" ref={inputField}>
                    {/* <table className="table mt-5 voyageTable table-responsive"> */}
                    {isLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5" >


                        {/* <div
                            className="stickyTablecus"
                            style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
                        > */}
                        <div className="stickyTablecus"
                            style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
                        >
                            <table className="table  table-vertical-center demotable text-center" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    {demandlist.length > 0 &&
                                        <tr>
                                            <th >ZONE NAME</th>
                                            <th >DIVISION NAME</th>
                                            <th >REGION</th>
                                            <th >TERRITORY</th>
                                            <th >Lpc</th>
                                            <th >ST.Rate</th>
                                            <th >NON EX %</th>
                                            <th  >NUMBER OF MEMO LINE</th>
                                            <th >TOTAL MEMO QTY</th>
                                            <th >TOTAL VISITED OUTLET</th>
                                            <th >TOTAL ORDER VALUE</th>
                                            <th  >NON Ex Value</th>
                                            <th >TOTAL IMS</th>
                                        </tr>
                                    }


                                </thead>
                                <tbody >
                                    {demandlist.length > 0 &&
                                        demandlist.map((item, index) => (

                                            <tr key={index}>
                                                {/* <td>{item.orderId}</td> */}
                                                <td>{item.zoneName}</td>
                                                <td>{item.divisionName}</td>
                                                <td>{item.areaName}</td>
                                                <td>{item.territoryName}</td>
                                                <td>{(item.lpc).toFixed(2)}</td>
                                                <td>{(item.strikeRate).toFixed(2)}%</td>
                                                <td>{(item.nonExecution).toFixed(2)}%</td>
                                                <td>{item.memoLine}</td>
                                                <td>{item.memoQty}</td>
                                                <td>{item.visitedOutlet}</td>
                                                <td>{item.orderValue}</td>
                                                <td>{(item.nonExeCutionValue).toFixed(2)}</td>
                                                <td>{(item.totalPrice).toFixed(2)}</td>
                                                {/* <td>
                                                {" "}
                                                <Link onClick={() => itemDetail(item)} title="Details">
                                                <i className="far fa-eye editIcon item-list-icon"></i>
                                                </Link>
                                                
                                                <Link onClick={() => gotoEditPage(item)} title="Edit">
                                                <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                                                </Link>

                                                <a
                                                href
                                                onClick={(id) => handleDeleteItem(item.orderId)}
                                                title="Delete"
                                                >
                                                <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                                                </a>
                                            </td> */}
                                            </tr>
                                        ))}

                                </tbody>
                            </table>


                            {/* </div> */}
                            {!isLoading && demandlist.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! Data List Not Found.
                                </div>
                            )}
                            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
                        </div>
                    </div>
                </div>
                

                <SimpleModal
                    size="xl"
                    show={itemDetailShow}
                    handleClose={() => setItemDetailShow(false)}
                    handleShow={() => setItemDetailShow(true)}
                    modalTitle={"Item Details"}
                >
                    <RemoteSalesDetails
                        handleClose={() => setItemDetailShow(false)}
                        itemID={itemID}
                        itemDetailnIfo={itemifo}
                    />
                </SimpleModal>

                {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default StrikeRateOfSecondary;
