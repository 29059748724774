import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  delteProductList,
  getDistributorList,
  getProductList,
  productSelecteditem,
  itemAddInput,
  productQuantity,
  InputHandle,
  salesSubmit,
  getCustomerBalance,
  cleanRemoteSalesData,
  getSalesGroupProductList,
  getSalesGroupProductListWithSpecialPrice,
  getSalesGroupProductListForEntry,
  getSalesGroupProductListWithSpecialPriceEntry,
  GetPrimarySalesDeliveryAllZoneEntry,
  GetNewCustomerBalanceByID,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { productSubmitStausRemove } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { GetAllZoneForEntryWithoutPC } from "../../_redux/actions/Zone/ZoneAction";

const RemoteSalesAdd = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const fillterProductList = useSelector(
    (state) => state.remoteSales.filterProduct
  );
  const productList = useSelector((state) => state.remoteSales.productList);
  console.log('entryproductList :>> ', productList);
  const submitLoading = useSelector((state) => state.remoteSales.submitLoading);
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const availableBalance = useSelector(
    (state) => state.remoteSales.availableBalance
  );
  const restBalance = useSelector(
    (state) => state.remoteSales.restBalance
  );

  const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
  const distributionSalesGroupId = useSelector((state) => state.remoteSales.distributionSalesGroupId);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);

  const submitData = useSelector((state) => state.remoteSales.submitData);
  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);
  const statusProduct = useSelector((state) => state.remoteSales.productSubmitStatus);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [terriory, setTerritory] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [roleType, setRoleType] = useState(0);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});

  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    console.log('user :>> ', user);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    setRoleType(user.role);
    // dispatch(getProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());
  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {
    if (statusProduct) {
      dispatch(productSubmitStausRemove());
      setTimeout(() => { history.go(0) }, 3000);
    }
  }, [statusProduct]);

  useEffect(() => {
    if (defultTerritory?.value) {
      dispatch(getDistributorList(defultTerritory?.value, salesType));
      inputChangeHandle("intDistributorId", 0);
    }
  }, [defultTerritory]);

  const SalesGroupdistributorList = useSelector(
    (state) => state.remoteSales.SalesGroupdistributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    //let zoneData = await getZoneListEntry();
    // Setzone(zoneData.data);

    let zoneData = await GetAllZoneForEntryWithoutPC();
    Setzone(zoneData.data);
  }

  const changeProductQuantity = (name, value) => {
    dispatch(productQuantity(name, value));
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
    
    // dispatch(getProductList(value));
  }

  const onSubmit = async (e) => {
    if (selectZone == null || selectZone == 0) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null || selectDivision == 0) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null || selectArea == 0) {
      alert("Please select Region");
      
      return false;
    } else if (selectTerritory == null || selectTerritory == 0) {
      alert("Please select Territory");
      return false;
    } else if (itemDataInput.intDistributorId == "") {
      alert("Please select Distribution");
      return false;
    }
    setDisabled(true);
    dispatch(salesSubmit(NewCustomerBalance, productList, selectZone, selectDivision, selectArea, selectTerritory, distributionSalesGroupId));
  };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3 ">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                {/* <span className="button-010">Sales Demand Entry</span> */}
                <h3 className="mb-0 pb-0">Sales Demand Entry</h3>
              </div>
            </div>
            {/* Check sales type  0 for all 1 for Biscuite and 3 for Bread */}

            <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            />

          </div>

          <hr></hr>
          <div className="form-group row"></div>

          <div className="form-group row p-4">
            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    // Clear division data when zone changes
                   // setdefultDivision(null);
                    //setdefaultArea(null);
                   // setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    //setdefaultArea(null);
                    //setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    // Clear selected territory
                    //setdefultTerritory(null); // or setdefultTerritory({});
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Territory</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={territoryDropdownData()}
                  value={defultTerritory}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                    setdefultTerritory(option)
                    dispatch(getDistributorList(option?.value, salesType));
                    setValue("intDistributorId", 0);

                  }}
                  defaultValue={defultTerritory}
                />
              </Form.Group>
            </div>



            <div className="col-lg-3 mb-5">
              <Form.Group as={Col} controlId="formGridState">

                <label className="form-label">Select Distributor</label>
                <RHFInput

                  as={<Select options={SalesGroupdistributorList} />}
                  rules={{ required: false }}
                  name="intDistributorId"
                  register={register}
                  value=""
                  // value={itemDataInput.intDistributorId}
                  onChange={(option) => {
                    inputChangeHandle("distributorName", option.label);
                    inputChangeHandle("intDistributorId", option.value?.intcusid);
                    //dispatch(getCustomerBalance(option.value?.intcusid));
                    dispatch(GetNewCustomerBalanceByID(option.value?.intcusid));
                    //dispatch(getProductList(option.value));
                    //dispatch(getSalesGroupProductList(salesType, option));
                    //dispatch(getSalesGroupProductListWithSpecialPrice(salesType, option,option.value?.intcusid));
                    //dispatch(getSalesGroupProductListWithSpecialPriceEntry(salesType, option,option.value?.intcusid));
                    dispatch(GetPrimarySalesDeliveryAllZoneEntry(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, 1, option.value?.intcusid, salesType, option));

                  }}
                  setValue={setValue}
                />
              </Form.Group>
              {/* <div className="inputError margin-minus-8">
                {errors.intDistributorId &&
                  errors.intDistributorId.ref.value === undefined &&
                  "Distributor can't be blank"}
              </div> */}

            </div>


          </div>

        </div>
        <div className="container-fluid mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            {roleType != 10 &&

              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                    <p>Credit Limit</p>
                  </div>
                  <div className="widget__right">
                    {balanceLoaderNew ? (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-warning" />
                      </div>
                    ) : (
                      <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                    )}
                  </div>
                </div>
              </div>

            }


            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Product Value</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Quantity(Pieces)</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Pending Qty</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.pendingProductQty < 0 ? 0 : item.pendingProductQty}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.quantity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                {/* {
                  submitLoading?
                  <button
                 
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit .....
                </button>:  */}
                <button
                  type="submit"
                  disabled={disabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>
                {/* } */}

              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default RemoteSalesAdd;
