import React from "react";
import { useSelector } from "react-redux";
import { Spring } from "react-spring";


const VerticalProgress2 = ({ progress }) => {
  const Secondaryachivement = useSelector((state) => state.dashboardcart.Secondaryachivement);
  var checkNan = isNaN(Secondaryachivement)?0:Secondaryachivement;
  console.log('Secondaryachivement :>> ', Secondaryachivement);

  return (
    <Spring from={{ percent: 0 }} to={{ percent: checkNan }}>
      {({ percent }) => (
        <><div> <span className="mr-2">100%</span></div>
         <div className="progress2 vertical2">
          <div style={{height: `${checkNan}%`}} className="progress-bar2">
            <span>{`${checkNan}%`}</span>
          </div>
        </div>
        </>
       
      )}
    </Spring>
  );
};

export default VerticalProgress2;
