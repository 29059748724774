import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from "react-chartjs-2";
import faker from 'faker';
import { GetDashboardTargetDate } from './DashboardCartAction';
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import DotLoading from "../../../../master/spinner/DotLoading";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export function PerDayTargetDashboard() {

  const dispatch = useDispatch();
  const dashboardInfo = useSelector((state) => state.dashboardcart.dashboardData);
  const deliveryPrice = useSelector((state) => state.dashboardcart.deliveryPrice);
 
  const secondaryPrice = useSelector((state) => state.dashboardcart.secondaryPrice);
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const ttldays = useSelector((state) => state.dashboardcart.ttldays);
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);

  let nf = new Intl.NumberFormat('en-BD');

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
            label: (context) => {
                const datasetLabel = context.dataset.label || "";
                const value = context.parsed.y;

                return [
                    `${datasetLabel}: ${value}`
                ].join("\n");
            },
        },
    },
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,
        text: 'Per day target',
      },
      grid: {
        position: 'front'
      }
    },
  };

  const verticleData1 = deliveryPrice;
  const verticleData2 = secondaryPrice;


  const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Primary',
        data: verticleData1,
        backgroundColor: '#FFFFFF',
        borderColor: '#733b09',
        pointBorder: '#FFFFFF',
        tension: '0.2',
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: 'Secondary',
        data: verticleData2,

        backgroundColor: '#FFFFFF',
        borderColor: '#0b8793',
        pointBorder: '#FFFFFF',
        tension: '0.2',
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: 'Target',
        //data: labels.map(() => faker.datatype.number({ min: dashboardInfo?.totalPrimaryTargetValue/31 , max: dashboardInfo?.targetQty/31 })),
        data: labels.map(() => faker.datatype.number({ min: Measurement == 1 ? dashboardInfo?.totalPrimaryTargetValue / ttldays : dashboardInfo?.targetSecondaryQty / ttldays, max: Measurement == 1 ? dashboardInfo?.totalPrimaryTargetValue / ttldays : dashboardInfo?.targetSecondaryQty / ttldays })),
        backgroundColor: '#44546a',
        borderColor: '#44546a',
        pointBorder: '#44546a',
        borderDash: [5, 5],
        pointRadius: 0,
        pointStyle: 'circle',
      },
    ],

  };
  return (

    <div className="top_card_dashboardChart_cfo" style={{ height: isLoading ? '280px' : 'auto' }}>
      <span>{isLoading && <DotLoading />}</span>
      <>
        {!isLoading && (
          <>
            <Line options={options} data={data} height={350} width={770} />
          </>
        )}
      </>
    </div>


  );
}
