import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";


const DistributorReportBrDataTable = () => {
    const [show, setShow] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const modalEditStatus = useSelector(
        (state) => state.CertificateListReducer.editStatus
    );

    const dispatch = useDispatch();
    const distributorBrReport = useSelector(
        (state) => state.disReport.distributorBrReport
    );

    console.log('distributorBrReport :>> ', distributorBrReport);

    const isLoading = useSelector((state) => state.disReport.isLoading);
    const disBrBalance = useSelector((state) => state.disReport.disBrBalance);
    const disBrTotalBalance = useSelector(
        (state) => state.disReport.disBrTotalBalance
    );


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (editItem) => {
        setEditItem(editItem);
        setShow(true);
    };

    useEffect(() => {
        // dispatch(getDistributorReport(areaInfo));
    }, []);

    const changePage = (data) => {
        setCurrentPage(data.page);
        // dispatch(getCertificateMasterList("", "", data.page));
    };



    const columns = [
        {
            name: "Distributor ID",
            selector: (row) => row.distID,
            sortable: true,
        },
        {
            name: "Distributor Name",
            selector: (row) => row.disName,
            sortable: true,
        },
        {
            name: "Opening Balance",
            selector: (row) => row.openingBalance,
            sortable: true,
        },
        {
            name: "Bank Receive",
            selector: (row) => row.bankReceive,
            sortable: true,
        },
        {
            name: "Delivery Amount",
            selector: (row) => row.deliveryAmount,
            sortable: true,
        },
        {
            name: "Net Balance",
            selector: (row) => row.netBalance,
            sortable: true,
        },

    ];
    let ref = React.createRef();
    const inputField = React.useRef(null);
    const componentRef = useRef();




    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
                <div className="container-fluid mb-3 containerFixed">
                    {distributorBrReport.length > 0 &&
                        <div className="row mt-3">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="paid__widget two">
                                    <div className="widget__left">
                                        <p>Total Bank Receive Balance</p>
                                    </div>

                                    <div className="widget__right">
                                        <h4>৳ {disBrBalance}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="paid__widget three">
                                    <div className="widget__left">
                                        <p>Net Balance</p>
                                    </div>
                                    <div className="widget__right">
                                        <h4>৳ {disBrTotalBalance.toFixed(2)}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
                {/* <DataTable
                    columns={columns}
                    data={distributorBrReport}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    selectableRowsHighlight
                    highlightOnHover
                /> */}

                <div className="row" id="id" ref={inputField}>

                    {isLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5" >

                        {/* <div
                            className="stickyTablecus"
                            style={{ height: distributorBrReport?.length > 0 ? 800 + "px" : 0 }}
                        > */}
                            <div className="stickyTablecus" 
                             style={{ height: distributorBrReport?.length > 0 ? 800 + "px" : 0 }}
                            >
                            <table className="table table-vertical-center text-center demotable" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    {distributorBrReport.length > 0 &&
                                        <tr>
                                            <th >Distributor ID</th>
                                            <th >Distributor NAME</th>
                                            <th >Opening Balance</th>
                                            <th >Bank Receive</th>
                                            <th >Delivery Amount</th>
                                            <th >Net Balance</th>
                                        </tr>
                                    }
                                </thead>
                                <tbody >
                                    {distributorBrReport.length > 0 &&
                                        distributorBrReport.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.distID}</td>
                                                <td>{item.disName}</td>
                                                <td>{item.openingBalance}</td>
                                                <td>{item.bankReceive}</td>
                                                <td>{item.deliveryAmount}</td>
                                                <td>{item.netBalance}</td>
                                            </tr>
                                        ))}

                                </tbody>
                            </table>

                        </div>
                    </div>
                    {!isLoading && distributorBrReport.length === 0 && (
                        <div className="alert alert-warning">
                            Sorry ! Data List Not Found.
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};

export default DistributorReportBrDataTable;
