import { indexOf } from "lodash";
import * as Types from "../../types/Types";

const initialState = {
  isLoading: false,
  zoneSubmitStatus: false,
  productUpdateStatus: false,
  productSubmitStatus: false,
  submitsecondaryStatus: false,
  fundSubmitStatus: false,
  restBalance: 0,
  salesDetails: [],
  productQuantity: 0,
  totalSalesDetailsAmount: 0,
  totalDetQuantity: null,
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  salesUpdateData: [],
  salesStockData: [],
  submitData: {
    intCustomer: 0,
    monCustBalance: 0,
    coaid: 0,
    intsalesofficeid: 0,
    DistribPointID: 0,
    DelPointID: 0,
  },
  balanceLoader: false,
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
  productCost: 0,
  productList: [],
  filterProduct: [],
  isLoggedIn: false,
  selectedProduct: [],
  availableBalance: 0,
  creditLimit: 0,
  demandlist: [],
  productDelivery: [],
  customerDeliveryProd: [],
  isPProductLoading: false,
  isDeDetails: false,
  salesEditInfo: null,
  submitLoading: false,
  salesApprovedList: [],
  salesApprovedDetails: [],
  approvedLoading: false,
  memoData: {
    memoId: 0,
    orderValue: 0,
    totalmemoQty: 0,
    totalSkuQty: 0,
    visitedOutlet: 0
  },
  fundDetails: [],
  ProductStockSum:0
};
const SecodnaryReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_PRODUCT_INPUT:
      const itemDataInput = { ...state.itemDataInput };
      itemDataInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInput,
      };
    case Types.HANDLE_MEMO_INPUT:
      const momoClInput = { ...state.memoData };
      momoClInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        memoData: momoClInput,
      };
    case Types.GET_DISTRIBUTOR_LIST:
      return {
        ...state,
        distributorList: getDistributor(action.payload.data),
      };

    case Types.ZONE_SAVE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        zoneSubmitStatus: true,
      };
    case Types.ZONE_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        zoneSubmitStatus: false,
      };
    case Types.GET_SEARCH_DATA_INPUT:
      const serchInput = { ...state.itemDataInput };
      serchInput.itemName = action.payload.value;

      let searchFilterData = [];
      let searchText = action.payload.value;
      if (searchText.length > 0) {
        searchFilterData = state.productList.filter((item) => {
          const filterProduct = item.zoneName + "" + item.zoneId;
          const itemData = filterProduct.toUpperCase();
          const textData = searchText.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      }
      return {
        ...state,
        filterProduct: searchFilterData,
        itemDataInput: serchInput,
      };
    case Types.GET_SALES_PRODUCT_LIST:
      console.log('action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        productList: action.payload.data,
      };

    case Types.GET_STOCK_SECONDARY_PRODUCT: {
      console.log('action.payload.data', action.payload.data)
      const productListWithSum = action.payload.data.map(item => ({
        ...item,
        ProductSum: (item.netStock ?? 0) * (item.prodTrdpricebypic ?? 0)
      }));

      const ProductStockSum = productListWithSum.reduce((total, item) => total + (item.ProductSum ?? 0), 0);

      return {
        ...state,
        productList: action.payload.data,
        ProductStockSum
      };
    }


    case Types.GET_STOCK_SECONDARY_PRODUCT_VALIDATION:
      console.log('action.payload.data[0] :>> ', action.payload.data[0]);
      const cloneProduct = state.productList.slice();

      for (let i = 0; i < cloneProduct.length; i++) {
        if (cloneProduct[i].itemID == action.payload.item.itemID) {
          cloneProduct[i].netStock = action.payload.data[0].netStock
        }
      }
      return {
        ...state,
        productList: cloneProduct,
      };
    case Types.GET_STOCK_SECONDARY_PRODUCT_VALIDATION_UPDATE:

      const cloneProductupdate = state.salesUpdateData.slice();

      // for (let i = 0; i < cloneProductupdate.length; i++) {
      //   if(cloneProductupdate[i].itemID === action.payload.item.itemID){
      //      cloneProductupdate[i].prodStock = action.payload.data[0].prodStock;
      //   }
      // }

      for (let i = 0; i < cloneProductupdate.length; i++) {
        if (cloneProductupdate[i].itemID === action.payload.item.itemID) {
          cloneProductupdate[i].netStock = action.payload.data[0].netStock;
          //  cloneProductupdate[i].upQty = action.payload.data[0].prodStock+action.payload.item.upQty;
        }
      }
      return {
        ...state,
        salesUpdateData: cloneProductupdate,
      };
    case Types.GET_SALES_APPROVED_SALES_REPORT:
      return {
        ...state,
        salesApprovedList: action.payload.data,
      };
    case Types.GET_SALES_APPROVED_DETAILS:
      return {
        ...state,
        salesApprovedDetails: action.payload.data,
        approvedLoading: action.payload.approvedLoading,
      };
    case Types.GET_SECONDARY_SALES_PRODUCT_DEMAND_LIST:
      return {
        ...state,
        demandlist: action.payload.data,
      };
    case Types.GET_CUSTOMER_PRODUCT_DELIVERY:
      return {
        ...state,
        productDelivery: action.payload.data,
        isPProductLoading: action.payload.isPProductLoading,
      };
    case Types.GET_PRODUCT_SELECTED:
      const productInput = { ...state.itemDataInput };
      productInput.itemName = "";
      const fetchProduct = state.selectedProduct;
      fetchProduct.push(action.payload);
      return {
        ...state,
        selectedProduct: fetchProduct,
        filterProduct: [],
        itemDataInput: productInput,
      };
    case Types.GET_PRODUCT_DELETE:
      const delteData = state.selectedProduct;
      delteData.splice(action.payload, 1);
      return {
        ...state,
        selectedProduct: delteData,
      };
    case Types.SALES_PRODUCT_SUBMIT:

      return {
        ...state,
        submitLoading: false,
        productSubmitStatus: true,
        availableBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        submitsecondaryStatus: action.payload.status,
      };

    case Types.SALES_PRODUCT_UPDATE:
      return {
        ...state,
        productUpdateStatus: true,
      };
    case Types.GET_SECONDARY_SALES_LIST_EDIT_DATA:
      return {
        ...state,
        salesEditInfo: action.payload,
      };
    case Types.PRODUCT_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        productSubmitStatus: false,
      };
    case Types.FUND_SUBMIT:
      return {
        ...state,
        submitLoading: false,
        fundSubmitStatus: true,
        fundDetails: action.payload.data,
      };
    case Types.GET_SALES_ORDER_DETAILS:
      let totalSum = 0;
      let totalQuantity = 0;



      for (let i = 0; i < action.payload.data.length; i++) {
        totalSum += action.payload.data[i].prodTrdprice;
        totalQuantity += action.payload.data[i].itemQuentityPic;
      }

      return {
        ...state,
        salesDetails: action.payload.data,
        totalSalesDetailsAmount: totalSum,
        totalDetQuantity: totalQuantity,
      };
    case Types.GET_CUSTOMER_PROD_DETAILS:
      // let totalSum = 0;
      // let totalQuantity = 0;

      // for (let i = 0; i < action.payload.data.length; i++) {
      //   totalSum += action.payload.data[i].totalPrice;
      //   totalQuantity += action.payload.data[i].itemQuentity;
      // }
      return {
        ...state,
        customerDeliveryProd: action.payload.data,
        isDeDetails: action.payload.isDeDetails,
        // totalSalesDetailsAmount: totalSum,
        // totalDetQuantity: totalQuantity,
      };
    case Types.GET_SECONDARY_SALES_ORDER_UPDATE_DETAILS:
      var newArray = [];
      const StockproductUpdate = action.payload.data.p_ItemList.slice();
      console.log('StockproductUpdate :>> ', StockproductUpdate);
      for (let i = 0; i < StockproductUpdate.length; i++) {
        StockproductUpdate[i].prodStock = StockproductUpdate[i].prodStock;
      }
      let orderValue = 0;
      let totalMemo = 0;
      let skyQty = 0;
      let visitedOutlet = 0
      let memoId = 0
      if (action.payload.data.p_Memo != null) {
        // let {orderValue=0,skuQty=0,totalMemo=0,visitedOutlet=0,memoId=0}=action.payload.data.p_Memo;
        orderValue = action.payload.data.p_Memo.orderValue;
        totalMemo = action.payload.data.p_Memo.totalMemo;
        visitedOutlet = action.payload.data.p_Memo.visitedOutlet;
        skyQty = action.payload.data.p_Memo.skuQty;
        memoId = action.payload.data.p_Memo.memoId;
      }
      let memoClone = { ...state.memoData };
      memoClone.orderValue = orderValue;
      memoClone.totalmemoQty = totalMemo;
      memoClone.totalSkuQty = skyQty;
      memoClone.visitedOutlet = visitedOutlet;
      memoClone.memoId = memoId
      return {
        ...state,
        salesUpdateData: StockproductUpdate,
        memoData: memoClone
      };
    case Types.CLEAR_REMOTE_SALES_DATA:
      const productClear = state?.productList?.slice();
      for (let i = 0; i < productClear?.length; i++) {
        productClear[i].itemQuentityPic = "";
      }

      return {
        ...state,
        availableBalance: 0,
        restBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        salesApprovedList: [],
        demandlist: [],
        productList: [],
        productSubmitStatus: false
      };
    case Types.DELETE_SECONDARY_SALES_TITEM:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.GET_CUSTOMER_BALANCE:
      let submitData = { ...state.submitData };
      submitData.intCustomer = action.payload.data.cusid;
      submitData.monCustBalance = action.payload.data.availableBalance;
      submitData.coaid = action.payload.data.intcoaid;
      submitData.intsalesofficeid = action.payload.data.intsalesoffid;
      submitData.disPointID = action.payload.data.disPointID;
      submitData.delPointID = action.payload.data.delPointID;

      return {
        ...state,
        availableBalance: action.payload.data.availableBalance,
        creditLimit: action.payload.data.moncreditlimit,
        balanceLoader: action.payload.isLoading,
        restBalance: action.payload.data.availableBalance,
        submitData,
      };
    case Types.CHANGE_PRODUCT_QUANTITY:
      let quantityvalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const product = state.productList.slice();
      console.log('seco product :>> ', product);
      const avaiblebalance = state.availableBalance;
      let sumItem = 0;
      let restBalance = 0;
      let rest;
      let productQuantity = 0;
      for (let i = 0; i < product.length; i++) {
        if (product[i].itemID === action.payload.item.itemID) {
          let quantity = product[i].itemQuentityPic == "" ? 0 : product[i].itemQuentityPic;
          if (product[i].netStock < quantityvalue) {
            product[i].itemQuentityPic = null;
            product[i].status = false;
          } else {
            product[i].status = true;
            product[i].itemQuentityPic = quantityvalue;

            product[i].totalPrice =
              quantity * product[i].prodTrdpricebypic;
          }

        }
        sumItem += product[i].itemQuentityPic == "" ? 0 : product[i].itemQuentityPic * product[i].prodTrdpricebypic;
        restBalance = avaiblebalance - sumItem;
        productQuantity += parseFloat(product[i].itemQuentityPic == null ? 0 : product[i].itemQuentityPic);
      }
      return {
        ...state,
        productList: product,
        productCost: sumItem,
        productQuantity: productQuantity,
        restBalance,
      };

    case Types.CHANGE_PRODUCT_UPDATE_QUANTITY:

      let quantityUpdatevalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const productUpdate = state.salesUpdateData.slice();
      let sumItemUpdate = 0;
      for (let i = 0; i < productUpdate.length; i++) {
        if (productUpdate[i].itemID === action.payload.item.itemID) {
          if (productUpdate[i].upQty + productUpdate[i].prodStock < quantityUpdatevalue) {
            productUpdate[i].itemQuentityPic = 0;
            productUpdate[i].status = false;
          } else {
            productUpdate[i].itemQuentityPic = quantityUpdatevalue;
          }

        }
        sumItemUpdate +=
          productUpdate[i].itemQuentityPic * productUpdate[i].prodTrdpricebypic;
      }
      return {
        ...state,
        salesUpdateData: productUpdate,
      };
    default:
      break;
  }
  return newState;
};

export default SecodnaryReducer;

const getDistributor = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.intcusid,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};
