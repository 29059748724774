import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {login } from "../_redux/authCrud";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// Initial form values
const initialValues = {
  username: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  // Validation schema
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required("Please provide a username"),
    password: Yup.string()
      .min(3, "Minimum 4 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Please provide a password"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);

      const response = await login(values.username, values.password);
      setLoading(false);
      if (response.status) {
        setStatus("Successfully Logged in!");
        window.location.href = "/dashboard";
        setIsLogged(true);
      } else {
        setStatus("Invalid username or password.");
        setIsLogged(false);
      }
    },
  });


  // Toggle password visibility
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Inline styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    // backgroundColor: '#C0C0C0',
  };

  const formStyle = {
    width: '300px',
    minHeight: '390px',
    padding: '20px',
    backgroundColor: '#FAEBD7',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '15px',
  };

  const inputStyle = {
    width: '100%', // Full width within the container
    padding: '12px 15px',
    borderRadius: '5px',
    border: '1px solid #ced4da',
    marginBottom: '4px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box', // Include padding and border in width
  };

  const inputFocusStyle = {
    borderColor: '#007bff',
  };

  const buttonStyle = {
    width: '100%', // Full width within the container
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxSizing: 'border-box', // Include padding and border in width
    marginBottom: '40px',
  };

  const buttonHoverStyle = {
    backgroundColor: '#0056b3',
    transform: 'scale(1.02)',
  };

  const eyeIconStyle = {
    position: 'absolute',
    right: '15px',
    top: '55%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  };

  // Dynamic classes for input and button
  const getInputStyle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return { ...inputStyle, borderColor: 'red' };
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return { ...inputStyle, ...inputFocusStyle };
    }
    return inputStyle;
  };

  return (
    <div className="mt-10" style={containerStyle}>
      <div style={formStyle}>
      <span style={{ fontWeight: 'bold',textAlign: 'center', }} ><img
            src={toAbsoluteUrl("/media/logos/abl_logo.png")}
            alt="AIL"
            style={{ height: '115px'}}
          />   </span>
        <div style={headerStyle}>
          <span style={{ fontSize: '26px', margin: '0',fontWeight: 'bold' }}>ABL | IMS  </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {formik.status && !isLogged && (
            <div
            style={{
              marginBottom: '10px',
              padding: '10px',
              backgroundColor: '#f8d7da',
              color: '#721c24',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            {formik.status}
          </div>
          
          )}
          {formik.status && isLogged && (
            <div
              style={{
                marginBottom: '10px',
                padding: '10px',
                backgroundColor: '#d4edda',
                color: '#155724',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              {formik.status}
            </div>
          )}
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="username" style={{ marginBottom: '2px' }}>Username</label>
            <input
              id="username"
              type="text"
              placeholder="Enter Email"
              style={getInputStyle('username')}
              name="username"
              {...formik.getFieldProps('username')}
            />
            {formik.touched.username && formik.errors.username && (
              <div style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.username}
              </div>
            )}
          </div>
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="password" style={{ marginBottom: '2px' }}>Password</label>
            <input
              id="password"
              type={showPassword ? 'password' : 'text'}
              placeholder="Enter Password"
              style={getInputStyle('password')}
              name="password"
              {...formik.getFieldProps('password')}
            />
            {
              showPassword ?<FaEyeSlash
              onClick={handleShowPassword}
              style={eyeIconStyle}
            />:
            <FaEye
              onClick={handleShowPassword}
              style={{ ...eyeIconStyle, display: showPassword ? 'none' : 'block' }}
            />
            }
            
            
            {formik.touched.password && formik.errors.password && (
              <div style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.password}
              </div>
            )}
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            style={{ ...buttonStyle, ...(loading ? buttonHoverStyle : {}) }}
          >
            {loading ? 'Signing in...' : 'Sign in'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
